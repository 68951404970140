import React, { useState, useEffect } from "react";
import SubHeader from "./SubHeader";
import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  Stack,
  Switch,
  Typography,
} from "@mui/material";
import CustomTextField from "./CustomTextField";
import { Link } from "react-router-dom";
import { AppRouts, TableName, StorageBucket } from "./Constant";
import { useFormik } from "formik";
import { useNavigate, useParams } from "react-router";
import * as yup from "yup";
import useSupabase from "./utils/useSupabase.jsx";
import ImageUpload from "./ImageUpload";
import { removeImage, uploadImage } from "./Helperfunction";

const AddPlacementCompanies = () => {
    const supabase = useSupabase();
    const navigate = useNavigate();
    const params = useParams();
    const [imagesPreview, setImagesPreview] = useState([]);
    const [originalImage, setOriginalImage] = useState();
    const [isNewImageUp, setIsNewImageUp] = useState(false);
    const initialValues = {
      code: "",
      name: "",
      isActive: false,
    };
  
    const validationSchema = yup.object({
      code: yup.string().required("Company Code is required"),
      name: yup.string().required("Company Name is required"),
    });
  
    useEffect(
      () => {
        const fetchPlacementData = async () => {
          const companyId = params.id;
          const { data, error } = await supabase
            .from(TableName.COMPANIES)
            .select()
            .eq("id", companyId);
  
          if (error) {
            console.error("Error fetching placement companies data:", error.message);
          } else if (data && data.length > 0) {
            const company = data[0];
            formik.setValues({
              code: company.code,
              name: company.name,
              isActive: company.isActive,
            });
            if (company.imageUrl) {
              setImagesPreview([{ imageUrl: company.imageUrl }]);
            }
            setOriginalImage(company.imageUrl);
          }
        };
  
        if (params.id) {
            fetchPlacementData();
        }
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [params.id, supabase]
    );
    const formik = useFormik({
      initialValues: initialValues,
      validationSchema: validationSchema,
      onSubmit: async (values, event) => {
        if (params.id) {
          let publicUrl = originalImage;
          if (isNewImageUp) {
            await removeImage(StorageBucket.COMPANIES, originalImage);
            publicUrl = await uploadImage(
              StorageBucket.COMPANIES,
              imagesPreview[0].imageFile
            );
          }
          const { data, error } = await supabase
            .from(TableName.COMPANIES)
            .update([
              {
                code: values.code,
                name: values.name,
                isActive: values.isActive,
                imageUrl: publicUrl,
              },
            ])
            .eq("id", params.id);
          if (!error) {
            console.log("placement companies data update", data);
            navigate(AppRouts.PLACEMENT);
          }
        } else {
          try {
            let publicUrl = "";
            if (imagesPreview.length > 0) {
              publicUrl = await uploadImage(
                StorageBucket.COMPANIES,
                imagesPreview[0].imageFile
              );
            }
            const { error: insertError } = await supabase
              .from(TableName.COMPANIES)
              .insert([
                {
                  code: values.code,
                  name: values.name,
                  isActive: values.isActive,
                  imageUrl: publicUrl,
                },
              ]);
  
            if (insertError) {
              console.error("Error inserting data:", insertError.message);
              return;
            }
            navigate(AppRouts.PLACEMENT);
          } catch (error) {
            console.error("Error:", error.message);
          }
        }
      },
    });
    const handleImageUpload = async (event) => {
      if (event?.target?.files?.length > 0) {
        const imageUrl = URL.createObjectURL(event?.target?.files[0]);
        setImagesPreview([
          {
            imageFile: event?.target?.files[0],
            imageUrl: imageUrl,
          },
        ]);
        setIsNewImageUp(true);
      }
    };
  
    const handleCancelImage = (index) => {
      setImagesPreview([]);
    };
    return (
      <div>
        <SubHeader
          title={params.id ? "Edit a Company" : "Add a new Company"}
          subtitle="Placement Companies"
          back="Placement Companies"
          linkProps={{
            to: AppRouts.PLACEMENT,
          }}
        />
        <div style={{ padding: "35px" }}>
          <form onSubmit={formik.handleSubmit}>
            <Box
              style={{
                padding: "20px",
                textAlign: "left",
                boxShadow: "0.5px 0.5px 5px 0.5px rgb(223, 218, 218)",
                borderRadius: "20px",
              }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12} md={5}>
                  <Typography variant="h6" sx={{ fontWeight: "400" }}>
                   Company Basic Details
                    <Typography sx={{ fontSize: "13px", color: "#a3a3b8" }}>
                      Please provide Company details
                    </Typography>
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Grid container>
                    <Grid item xs={12}>
                      <div style={{ margin: 17 }}>
                        <ImageUpload
                          isMultiImages={false}
                          handleImageUpload={handleImageUpload}
                          handleCancelImage={handleCancelImage}
                          uploadedImages={imagesPreview}
                          title="Click to upload Certification Image"
                        />
                      </div>
                    </Grid>
  
                    <Grid item xs={12}>
                      <InputLabel>
                        <Typography sx={{ color: "black", fontWeight: "500" }}>
                          Code
                          <Typography sx={{ color: "red" }} component="span">
                            *
                          </Typography>
                        </Typography>
                      </InputLabel>
                      <CustomTextField
                        name="code"
                        value={formik.values.code}
                        onChange={(e) => {
                          const { value } = e.target;
                          if (
                            /^[a-zA-Z0-9]*$/.test(value) &&
                            value.length <= 10
                          ) {
                            formik.handleChange(e);
                          }
                        }}
                        onBlur={formik.handleBlur}
                        error={formik.touched.code && Boolean(formik.errors.code)}
                      />
                      {formik.touched.code && formik.errors.code && (
                        <Typography
                          sx={{
                            color: "#d32f2f",
                            fontSize: "0.75rem",
                            mt: "-15px",
                          }}
                        >
                          {formik.errors.code}
                        </Typography>
                      )}
                    </Grid>
                    <br />
                    <Grid item xs={12}>
                      <InputLabel>
                        <Typography sx={{ color: "black", fontWeight: "500" }}>
                          Name
                          <Typography sx={{ color: "red" }} component="span">
                            *
                          </Typography>
                        </Typography>
                      </InputLabel>
                      <CustomTextField
                        name="name"
                        value={formik.values.name}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.name && Boolean(formik.errors.name)}
                      />
                      {formik.touched.name && formik.errors.name && (
                        <Typography
                          sx={{
                            color: "#d32f2f",
                            fontSize: "0.75rem",
                            mt: "-15px",
                          }}
                        >
                          {formik.errors.name}
                        </Typography>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
            <br />
            <br />
            <br />
            <br />
            <Box
              style={{
                padding: "20px",
                textAlign: "left",
                boxShadow: "0.5px 0.5px 5px 0.5px rgb(223, 218, 218)",
                borderRadius: "20px",
              }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12} md={5}>
                  <Typography variant="h6" sx={{ fontWeight: "400" }}>
                    Status
                    <Typography sx={{ fontSize: "13px", color: "#a3a3b8" }}>
                      Please provide your Company status
                    </Typography>
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Grid container>
                    <Grid item xs={12}>
                      <FormControl fullWidth>
                        <Stack direction="row">
                          <Typography>Active</Typography>
                          <Switch
                            name="isActive"
                            color="primary"
                            checked={formik.values.isActive}
                            onChange={formik.handleChange}
                          />
                        </Stack>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
            <Box pt={3} display="flex" flexDirection="row-reverse">
              <Stack direction="row" spacing={4}>
                <Link to={AppRouts.PLACEMENT}>
                  <Button type="cancle" variant="contained" color="primary">
                    Cancel
                  </Button>
                </Link>
                <Button type="submit" variant="contained" color="primary">
                  Submit
                </Button>
              </Stack>
            </Box>
          </form>
        </div>
      </div>
    );
}

export default AddPlacementCompanies