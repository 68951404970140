import * as React from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import { TextField, InputAdornment } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import logo from "../src/image/etsround.png";
import useSupabase from "./utils/useSupabase";
import { toast } from "react-toastify";
import { useEffect } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useState } from "react";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";
import { useNavigate } from "react-router";
import CircularProgress from "@mui/material/CircularProgress";
import "react-toastify/dist/ReactToastify.css";
function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://www.educampustech.com/">
      EduCampus Technologies. All rights reserved
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

// TODO remove, this demo shouldn't need to reset the theme.

const defaultTheme = createTheme();

export default function SignIn({ Auth, setAuth, get }) {
  const supabase = useSupabase();
  const navigate = useNavigate();
  const [Password, setPassword] = useState(false);
  const handleClickPassword = () => setPassword((show) => !show);
  const [isLoading, setisLoading] = useState(false);
  // const get = async () => {
  //   const data = await supabase.auth.getSession();
  //   if (data.data.session !== null) {
  //     console.log(data);
  //     setAuth(true);
  //   }
  // };
  // useEffect(() => {
  //   get();
  // }, []);

  const showToast = (type, message) => {
    switch (type) {
      case "success":
        toast.success(message);
        break;
      case "error":
        toast.error(message);
        break;
      case "info":
        toast.info(message);
        break;
      case "warning":
        toast.warning(message);
        break;
      default:
        toast(message);
        break;
    }
  };
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Invalid email")
        .test(
          "starts-with-number",
          "Email cannot start with a number",
          (value) => {
            return !/^\d/.test(value);
          }
        )
        .required("Email is required"),
      password: Yup.string().required("Password is required"),
    }),
    onSubmit: async (values) => {
      if (process.env.REACT_APP_ADMIN === values.email) {
        console.log(process.env.REACT_APP_ADMIN);
        toast.error("Invaild email id");
      } else {
        let { data, error } = await supabase.auth.signInWithPassword({
          email: values.email,
          password: values.password,
        });
        // console.log(error.error_description, "dsdsd", error);
        if (error) {
          toast.error(error.message);
        }
        window.location.reload();
        get();
      }
    },
  });
  const handleSubmit = async (event) => {
    event.preventDefault();
    const data1 = new FormData(event.currentTarget);
    let { data, error } = await supabase.auth.signInWithPassword({
      email: data1.get("email"),
      password: data1.get("password"),
    });
    console.log(
      {
        email: data1.get("email"),
        password: data1.get("password"),
      },
      data
    );
    window.location.reload();
    get();
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img src={logo} width={"40%"} alt="logo"></img>

          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <Box
            component="form"
            onSubmit={formik.handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            <Box sx={{ mt: 3 }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    name="email"
                    variant="outlined"
                    label="Email *"
                    fullWidth
                    placeholder="your email "
                    value={formik.values.email}
                    onBlur={formik.handleBlur}
                    sx={{ "& .MuiFormHelperText-root": { ml: 0 } }}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    helperText={formik.touched.email && formik.errors.email}
                    onChange={formik.handleChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    className="txtup"
                    name="password"
                    fullWidth
                    label="Password *"
                    placeholder="your password "
                    type={Password ? "text" : "password"}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="start">
                          <span>
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickPassword}
                              sx={{
                                color:
                                  formik.touched.password &&
                                  formik.errors.password
                                    ? "red"
                                    : "#424242",
                              }}
                            >
                              {Password ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          </span>
                        </InputAdornment>
                      ),
                    }}
                    variant="outlined"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    sx={{ "& .MuiFormHelperText-root": { ml: 0 } }}
                    error={
                      formik.touched.password && Boolean(formik.errors.password)
                    }
                    helperText={
                      formik.touched.password && formik.errors.password
                    }
                    value={formik.values.password}
                  />
                </Grid>
              </Grid>

              {/* {message && <p className="signinp">{message}</p>} */}
              <Grid container justifyContent="flex-end">
                <Grid item></Grid>
              </Grid>
            </Box>
            <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Sign In
            </Button>
            {/* <Grid container>
              <Grid item xs>
                <Link href="#" variant="body2">
                  Forgot password?
                </Link>
              </Grid>
            </Grid> */}
          </Box>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    </ThemeProvider>
  );
}
