import React, { useState } from "react";
import {
  Checkbox,
  FormControlLabel,
  Typography,
  Popover,
  Paper,
  Box,
  Stack,
} from "@mui/material";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
function CommonFilter({ id, title, options, selectedOptions, onFilterChange }) {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleDropdownClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  const handleChange = (item) => {
    const updatedSelection = [...selectedOptions];
    const index = updatedSelection.findIndex(
      (_rec) => _rec.id === id && _rec.code === item.code
    );

    if (index === -1) {
      updatedSelection.push({
        id: id,
        code: item.code,
        value: item.value,
        title: title,
      });
    } else {
      updatedSelection.splice(index, 1);
    }

    onFilterChange(updatedSelection);
  };

  const open = Boolean(anchorEl);
  const popoverId = open ? "popover" : undefined;
  return (
    <div className="filterBox">
      <Box className="filter" onClick={handleDropdownClick}>
        <Stack direction="row">
          <Typography
            className="filterText"
            sx={{
              color: "rgb(47, 55, 70)",
              fontWeight: "600",
              fontSize: "12.5px",
              mt: "9px",
              cursor: "pointer",
              marginLeft: "13px",
            }}
          >
            {title}
          </Typography>
          <KeyboardArrowDownOutlinedIcon
            sx={{ marginTop: "7PX", cursor: "pointer" }}
          />
        </Stack>
      </Box>
      <Popover
        id={popoverId}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClosePopover}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <Paper>
          <div style={{ padding: "16px" }}>
            {options ? (
              options.map((option) => (
                <div key={option.code}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        type="checkbox"
                        checked={
                          selectedOptions.findIndex(
                            (_selOpt) =>
                              _selOpt.id === id && _selOpt.code === option.code
                          ) >= 0
                        }
                        onChange={() => handleChange(option)}
                      />
                    }
                    label={option.value}
                  />
                </div>
              ))
            ) : (
              <p>No options available</p>
            )}
          </div>
        </Paper>
      </Popover>
    </div>
  );
}

export default CommonFilter;
