import React, { useEffect, useState } from "react";
import SubHeader from "./SubHeader";
import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import ImageUpload from "./ImageUpload";
import CustomTextField from "./CustomTextField";
import { useFormik } from "formik";
import * as yup from "yup";
import useSupabase from "./utils/useSupabase";
import { useParams, useNavigate, Link } from "react-router-dom";
import { AppRouts, StorageBucket, TableName } from "./Constant";
import { showToast } from "./toastUtils";
import { removeImage, uploadImage } from "./Helperfunction";

const AddBlog = () => {
  const supabase = useSupabase();
  const params = useParams();
  const navigate = useNavigate();
  const [imagesPreview, setImagesPreview] = useState([]);
  const [originalImage, setOriginalImage] = useState();
  const [isNewImageUp, setIsNewImageUp] = useState(false);
  const initialValues = {
    name: "",
    shortDesc: "",
    desc1: "",
    desc2: "",
    desc3: "",
    desc4: "",
    desc5: "",
    isActive: false,
  };

  const validationSchema = yup.object({
    name: yup.string().required("Blog Title is required"),
    shortDesc: yup.string().required("Short Description is required"),
  });
  useEffect(
    () => {
      const fetchBlogData = async () => {
        const blogId = params.id;
        console.log("blogId", blogId);
        const { data, error } = await supabase
          .from(TableName.BLOG)
          .select()
          .eq("id", blogId);

        if (error) {
          console.error("Error fetching blog data:", error.message);
        } else if (data && data.length > 0) {
          const blog = data[0];
          formik.setValues({
            name: blog.name,
            shortDesc: blog.shortDesc,
            desc1: blog.desc1,
            desc2: blog.desc2,
            desc3: blog.desc3,
            desc4: blog.desc4,
            desc5: blog.desc5,
            isActive: blog.isActive,
          });
          if (blog.imageUrl) {
            setImagesPreview([{ imageUrl: blog.imageUrl }]);
          }
          setOriginalImage(blog.imageUrl);
        }
      };

      if (params.id) {
        fetchBlogData();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [params.id, supabase]
  );
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: async (values, event) => {
      if (params.id) {
        let publicUrl = originalImage;
        if (isNewImageUp) {
          await removeImage(StorageBucket.BLOG, originalImage);
          publicUrl = await uploadImage(
            StorageBucket.BLOG,
            imagesPreview[0].imageFile
          );
        }
        const { data, error } = await supabase
          .from(TableName.BLOG)
          .update([
            {
              name: values.name,
              shortDesc: values.shortDesc,
              desc1: values.desc1,
              desc2: values.desc2,
              desc3: values.desc3,
              desc4: values.desc4,
              desc5: values.desc5,
              imageUrl: publicUrl,
              isActive: values.isActive,
            },
          ])
          .eq("id", params.id);
        if (!error) {
          console.log("Blog data update", data);
          navigate(AppRouts.BLOG);
          showToast("update", "Blog");
        }
      } else {
        try {
          let publicUrl = "";
          if (imagesPreview.length > 0) {
            publicUrl = await uploadImage(
              StorageBucket.BLOG,
              imagesPreview[0].imageFile
            );
          }
          const { error: insertError } = await supabase
            .from(TableName.BLOG)
            .insert([
              {
                name: values.name,
                shortDesc: values.shortDesc,
                desc1: values.desc1,
                desc2: values.desc2,
                desc3: values.desc3,
                desc4: values.desc4,
                desc5: values.desc5,
                imageUrl: publicUrl,
                isActive: values.isActive,
              },
            ]);

          if (insertError) {
            console.error("Error inserting data:", insertError.message);
            return;
          }

          navigate(AppRouts.BLOG);
          showToast("success", "Blog");
        } catch (error) {
          console.error("Error:", error.message);
        }
      }
    },
  });
  const handleImageUpload = async (event) => {
    if (event?.target?.files?.length > 0) {
      const imageUrl = URL.createObjectURL(event?.target?.files[0]);
      setImagesPreview([
        {
          imageFile: event?.target?.files[0],
          imageUrl: imageUrl,
        },
      ]);
      setIsNewImageUp(true);
      setOriginalImage(imageUrl);
    }
  };

  const handleCancelImage = (index) => {
    setImagesPreview([]);
    setOriginalImage(null);
  };
  return (
    <div>
      <SubHeader
        title={params.id ? "Edit your blog" : "Add a new blog"}
        subtitle="Blog"
        back="Blog"
        linkProps={{
          to: "/Blog",
        }}
      />
      <div style={{ padding: "35px" }}>
        <form onSubmit={formik.handleSubmit}>
          <Box
            style={{
              padding: "20px",
              textAlign: "left",
              boxShadow: "0.5px 0.5px 5px 0.5px rgb(223, 218, 218)",
              borderRadius: "20px",
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} md={5}>
                <Typography variant="h6" sx={{ fontWeight: "400" }}>
                  Blog Basic Details
                  <Typography sx={{ fontSize: "13px", color: "#a3a3b8" }}>
                    Please provide Blog details
                  </Typography>
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Grid container>
                  <Grid item xs={12}>
                    <div style={{ margin: 17 }}>
                      <ImageUpload
                        isMultiImages={false}
                        handleImageUpload={handleImageUpload}
                        handleCancelImage={handleCancelImage}
                        uploadedImages={imagesPreview}
                        title="Click to upload Blog Image"
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <InputLabel>
                      <Typography sx={{ color: "black", fontWeight: "500" }}>
                        Blog Title
                        <Typography sx={{ color: "red" }} component="span">
                          *
                        </Typography>
                      </Typography>
                    </InputLabel>
                    <CustomTextField
                      name="name"
                      value={formik.values.name}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={formik.touched.name && Boolean(formik.errors.name)}
                    />
                    {formik.touched.name && formik.errors.name && (
                      <Typography
                        sx={{
                          color: "#d32f2f",
                          fontSize: "0.75rem",
                          mt: "-15px",
                        }}
                      >
                        {formik.errors.name}
                      </Typography>
                    )}
                  </Grid>
                  <br />
                  <Grid item xs={12}>
                    <InputLabel>
                      <Typography sx={{ color: "black", fontWeight: "500" }}>
                        Short Description
                        <Typography sx={{ color: "red" }} component="span">
                          *
                        </Typography>
                      </Typography>
                    </InputLabel>
                    <TextField
                      sx={{ width: "80%" }}
                      id="outlined-multiline-static"
                      multiline
                      rows={2}
                      name="shortDesc"
                      value={formik.values.shortDesc}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.shortDesc &&
                        Boolean(formik.errors.shortDesc)
                      }
                    />
                    {formik.touched.shortDesc && formik.errors.shortDesc && (
                      <Typography
                        sx={{
                          color: "#d32f2f",
                          fontSize: "0.75rem",
                        }}
                      >
                        {formik.errors.shortDesc}
                      </Typography>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
          <br />
          <Box
            style={{
              padding: "20px",
              textAlign: "left",
              boxShadow: "0.5px 0.5px 5px 0.5px rgb(223, 218, 218)",
              borderRadius: "20px",
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} md={5}>
                <Typography variant="h6" sx={{ fontWeight: "400" }}>
                  Blog Description
                  <Typography sx={{ fontSize: "13px", color: "#a3a3b8" }}>
                    Please provide Blog Description
                  </Typography>
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Grid container>
                  <br />
                  <Grid item xs={12}>
                    <InputLabel>
                      <Typography
                        sx={{ color: "black", fontWeight: "500", pt: "10px" }}
                      >
                        Blog Description 1
                      </Typography>
                    </InputLabel>
                    <TextField
                      sx={{ width: "80%" }}
                      id="outlined-multiline-static"
                      multiline
                      rows={4}
                      name="desc1"
                      value={formik.values.desc1}
                      onChange={formik.handleChange}
                    />
                  </Grid>
                  <br />
                  <Grid item xs={12}>
                    <InputLabel>
                      <Typography
                        sx={{ color: "black", fontWeight: "500", pt: "10px" }}
                      >
                        Blog Description 2
                      </Typography>
                    </InputLabel>
                    <TextField
                      sx={{ width: "80%" }}
                      id="outlined-multiline-static"
                      multiline
                      rows={4}
                      name="desc2"
                      value={formik.values.desc2}
                      onChange={formik.handleChange}
                    />
                  </Grid>
                  <br />
                  <Grid item xs={12}>
                    <InputLabel>
                      <Typography
                        sx={{ color: "black", fontWeight: "500", pt: "10px" }}
                      >
                        Blog Description 3
                      </Typography>
                    </InputLabel>
                    <TextField
                      sx={{ width: "80%" }}
                      id="outlined-multiline-static"
                      multiline
                      rows={4}
                      name="desc3"
                      value={formik.values.desc3}
                      onChange={formik.handleChange}
                    />
                  </Grid>
                  <br />
                  <Grid item xs={12}>
                    <InputLabel>
                      <Typography
                        sx={{ color: "black", fontWeight: "500", pt: "10px" }}
                      >
                        Blog Description 4
                      </Typography>
                    </InputLabel>
                    <TextField
                      sx={{ width: "80%" }}
                      id="outlined-multiline-static"
                      multiline
                      rows={4}
                      name="desc4"
                      value={formik.values.desc4}
                      onChange={formik.handleChange}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <InputLabel>
                      <Typography
                        sx={{ color: "black", fontWeight: "500", pt: "10px" }}
                      >
                        Blog Description 5
                      </Typography>
                    </InputLabel>
                    <TextField
                      sx={{ width: "80%" }}
                      id="outlined-multiline-static"
                      multiline
                      rows={4}
                      name="desc5"
                      value={formik.values.desc5}
                      onChange={formik.handleChange}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
          <br />
          <br />
          <Box
            style={{
              padding: "20px",
              textAlign: "left",
              boxShadow: "0.5px 0.5px 5px 0.5px rgb(223, 218, 218)",
              borderRadius: "20px",
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} md={5}>
                <Typography variant="h6" sx={{ fontWeight: "400" }}>
                  Blog Status
                  <Typography sx={{ fontSize: "13px", color: "#a3a3b8" }}>
                    Please provide your Blog status
                  </Typography>
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Grid container>
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <Stack direction="row">
                        <Typography>Active</Typography>
                        <Switch
                          name="isActive"
                          color="primary"
                          checked={formik.values.isActive}
                          onChange={formik.handleChange}
                        />
                      </Stack>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
          <Box pt={3} display="flex" flexDirection="row-reverse">
            <Stack direction="row" spacing={4}>
              <Link to={AppRouts.BLOG}>
                <Button type="cancle" variant="contained" color="primary">
                  Cancel
                </Button>
              </Link>
              <Button type="submit" variant="contained" color="primary">
                Submit
              </Button>
            </Stack>
          </Box>
        </form>
      </div>
    </div>
  );
};

export default AddBlog;
