import React, { useEffect, useState } from "react";
import SubHeader from "./SubHeader";
import { Link, useParams } from "react-router-dom";
import { AppRouts, StorageBucket, TableName } from "./Constant";
import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  Rating,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import CustomTextField from "./CustomTextField";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import useSupabase from "./utils/useSupabase";
import { showToast } from "./toastUtils";
import ImageUpload from "./ImageUpload";
import { removeImage, uploadImage } from "./Helperfunction";

const AddWebsiteReview = () => {
  const navigate = useNavigate();
  const supabase = useSupabase();
  const params = useParams();
  const [imagesPreview, setImagesPreview] = useState([]);
  const [originalImage, setOriginalImage] = useState();
  const [isNewImageUp, setIsNewImageUp] = useState(false);
  const initialValues = {
    name: "",
    company: "",
    package: "",
    link: "",
    review: "",
    rating: "",
    isActive: false,
  };
  const validationSchema = yup.object({
    name: yup.string().required("Name is required"),
    company: yup.string().required("Company is required"),
    package: yup.string().required("Package is required"),
    review: yup.string().required("Review is required"),
    rating: yup.string().required("Rating is required"),
  });
  useEffect(
    () => {
      const fetchReviewData = async () => {
        const reviewId = params.id;
        const { data, error } = await supabase
          .from(TableName.WEBSITEREVIEW)
          .select()
          .eq("id", reviewId);

        if (error) {
          console.error("Error fetching review data:", error.message);
        } else if (data && data.length > 0) {
          const review = data[0];
          formik.setValues({
            name: review.name,
            company: review.company,
            package: review.package,
            link: review.link,
            review: review.review,
            rating: review.rating,
            isActive: review.isActive,
          });
          if (review.imageUrl) {
            setImagesPreview([{ imageUrl: review.imageUrl }]);
          }
          setOriginalImage(review.imageUrl);
        }
      };
      if (params.id) {
        fetchReviewData();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [params.id, supabase]
  );
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const firstLetter = values.name.charAt(0).toUpperCase();
      if (params.id) {
        let publicUrl = originalImage;
        if (isNewImageUp) {
          await removeImage(StorageBucket.WEBSITEREVIEW, originalImage);
          publicUrl = await uploadImage(
            StorageBucket.WEBSITEREVIEW,
            imagesPreview[0].imageFile
          );
        }
        const { data, error } = await supabase
          .from(TableName.WEBSITEREVIEW)
          .update([
            {
              name: values.name,
              company: values.company,
              package: values.package,
              link: values.link,
              review: values.review,
              rating: values.rating,
              isActive: values.isActive,
              firstLetter: firstLetter,
              imageUrl: publicUrl,
            },
          ])
          .eq("id", params.id);
        if (!error) {
          console.log("Review data updated", data);
          navigate(AppRouts.WEBSITEREVIEW);
          showToast("update", "Review");
        } else {
          console.error("Error updating data:", error.message);
        }
      } else {
        try {
          let publicUrl = "";
          if (imagesPreview.length > 0) {
            publicUrl = await uploadImage(
              StorageBucket.WEBSITEREVIEW,
              imagesPreview[0].imageFile
            );
          }
          const { error: insertError } = await supabase
            .from(TableName.WEBSITEREVIEW)
            .insert([
              {
                name: values.name,
                company: values.company,
                package: values.package,
                link: values.link,
                review: values.review,
                rating: values.rating,
                isActive: values.isActive,
                firstLetter: firstLetter,
                imageUrl: publicUrl,
              },
            ]);

          if (insertError) {
            console.error("Error inserting data:", insertError.message);
            return;
          }

          navigate(AppRouts.WEBSITEREVIEW);
          showToast("success", "Review");
        } catch (error) {
          console.error("Error:", error.message);
        }
      }
    },
  });
  const handleImageUpload = async (event) => {
    if (event?.target?.files?.length > 0) {
      const imageUrl = URL.createObjectURL(event?.target?.files[0]);
      setImagesPreview([
        {
          imageFile: event?.target?.files[0],
          imageUrl: imageUrl,
        },
      ]);
      setIsNewImageUp(true);
      setOriginalImage(imageUrl);
    }
  };

  const handleCancelImage = (index) => {
    setImagesPreview([]);
    setOriginalImage(null);
  };
  return (
    <div>
      <SubHeader
        title={params.id ? "Edit a Review " : "Add a new Review"}
        subtitle="Review"
        back="Review"
        linkProps={{
          to: AppRouts.WEBSITEREVIEW,
        }}
      />
      <div style={{ padding: "35px" }}>
        <form onSubmit={formik.handleSubmit}>
          <Box
            style={{
              padding: "20px",
              textAlign: "left",
              boxShadow: "0.5px 0.5px 5px 0.5px rgb(223, 218, 218)",
              borderRadius: "20px",
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} md={5}>
                <Typography variant="h6" sx={{ fontWeight: "400" }}>
                  Review Basic Details
                  <Typography sx={{ fontSize: "13px", color: "#a3a3b8" }}>
                    Please provide Review details
                  </Typography>
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Grid container>
                  <Grid item xs={12}></Grid>
                  <br />
                  <ImageUpload
                    isMultiImages={false}
                    handleImageUpload={handleImageUpload}
                    handleCancelImage={handleCancelImage}
                    uploadedImages={imagesPreview}
                    title="Click to upload student Image"
                  />
                  <br />
                  <Grid item xs={12}>
                    <InputLabel>
                      <Typography sx={{ color: "black", fontWeight: "500" }}>
                        Name
                        <Typography sx={{ color: "red" }} component="span">
                          *
                        </Typography>
                      </Typography>
                    </InputLabel>
                    <CustomTextField
                      name="name"
                      value={formik.values.name}
                      onChange={(e) => {
                        const { value } = e.target;
                        const capitalizedValue =
                          value.charAt(0).toUpperCase() + value.slice(1);
                        if (capitalizedValue.length <= 50) {
                          formik.handleChange({
                            target: {
                              name: "name",
                              value: capitalizedValue,
                            },
                          });
                        }
                      }}
                      onBlur={formik.handleBlur}
                      error={formik.touched.name && Boolean(formik.errors.name)}
                    />
                    {formik.touched.name && formik.errors.name && (
                      <Typography
                        sx={{
                          color: "#d32f2f",
                          fontSize: "0.75rem",
                          mt: "-15px",
                        }}
                      >
                        {formik.errors.name}
                      </Typography>
                    )}
                    <InputLabel>
                      <Typography sx={{ color: "black", fontWeight: "500" }}>
                        Company Name
                        <Typography sx={{ color: "red" }} component="span">
                          *
                        </Typography>
                      </Typography>
                    </InputLabel>
                    <CustomTextField
                      name="company"
                      value={formik.values.company}
                      onChange={(e) => {
                        const { value } = e.target;
                        const capitalizedValue =
                          value.charAt(0).toUpperCase() + value.slice(1);
                        if (capitalizedValue.length <= 50) {
                          formik.handleChange({
                            target: {
                              name: "company",
                              value: capitalizedValue,
                            },
                          });
                        }
                      }}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.company && Boolean(formik.errors.company)
                      }
                    />
                    {formik.touched.company && formik.errors.company && (
                      <Typography
                        sx={{
                          color: "#d32f2f",
                          fontSize: "0.75rem",
                          mt: "-15px",
                        }}
                      >
                        {formik.errors.company}
                      </Typography>
                    )}
                    <InputLabel>
                      <Typography sx={{ color: "black", fontWeight: "500" }}>
                        LinkedIn Link
                      </Typography>
                    </InputLabel>
                    <CustomTextField
                      name="link"
                      value={formik.values.link}
                      onChange={formik.handleChange}
                    />
                    <InputLabel>
                      <Typography sx={{ color: "black", fontWeight: "500" }}>
                        Package
                        <Typography sx={{ color: "red" }} component="span">
                          *
                        </Typography>
                      </Typography>
                    </InputLabel>
                    <CustomTextField
                      name="package"
                      value={formik.values.package}
                      onChange={(e) => {
                        const { value } = e.target;
                        const capitalizedValue =
                          value.charAt(0).toUpperCase() + value.slice(1);
                        if (capitalizedValue.length <= 50) {
                          formik.handleChange({
                            target: {
                              name: "package",
                              value: capitalizedValue,
                            },
                          });
                        }
                      }}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.package && Boolean(formik.errors.package)
                      }
                    />
                    {formik.touched.package && formik.errors.package && (
                      <Typography
                        sx={{
                          color: "#d32f2f",
                          fontSize: "0.75rem",
                          mt: "-15px",
                        }}
                      >
                        {formik.errors.package}
                      </Typography>
                    )}
                    <Grid item xs={12}>
                      <InputLabel>
                        <Typography sx={{ color: "black", fontWeight: "500" }}>
                          Review Description
                          <Typography sx={{ color: "red" }} component="span">
                            *
                          </Typography>
                        </Typography>
                      </InputLabel>
                      <TextField
                        name="review"
                        value={formik.values.review}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.review && Boolean(formik.errors.review)
                        }
                        sx={{ width: "80%" }}
                        id="outlined-multiline-static"
                        multiline
                        rows={4}
                      />
                    </Grid>

                    {formik.touched.review && formik.errors.review && (
                      <Typography
                        sx={{
                          color: "#d32f2f",
                          fontSize: "0.75rem",
                        }}
                      >
                        {formik.errors.review}
                      </Typography>
                    )}
                    <InputLabel>
                      <Typography sx={{ color: "black", fontWeight: "500" }}>
                        Rate us
                        <Typography sx={{ color: "red" }} component="span">
                          *
                        </Typography>
                      </Typography>
                    </InputLabel>
                    <Rating
                      name="rating"
                      size="large"
                      value={formik.values.rating}
                      onChange={(event, newValue) => {
                        formik.setFieldValue("rating", newValue);
                      }}
                      onBlur={formik.handleBlur}
                    />
                    {formik.touched.rating && formik.errors.rating && (
                      <Typography
                        sx={{
                          color: "#d32f2f",
                          fontSize: "0.75rem",
                          paddingLeft: "20px",
                        }}
                      >
                        {formik.errors.rating}
                      </Typography>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
          <br />
          <br />
          <Box
            style={{
              padding: "20px",
              textAlign: "left",
              boxShadow: "0.5px 0.5px 5px 0.5px rgb(223, 218, 218)",
              borderRadius: "20px",
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} md={5}>
                <Typography variant="h6" sx={{ fontWeight: "400" }}>
                  Review Status
                  <Typography sx={{ fontSize: "13px", color: "#a3a3b8" }}>
                    Please provide your Review status
                  </Typography>
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Grid container>
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <Stack direction="row">
                        <Typography>Active</Typography>
                        <Switch
                          name="isActive"
                          color="primary"
                          checked={formik.values.isActive}
                          onChange={formik.handleChange}
                        />
                      </Stack>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
          <Box pt={3} display="flex" flexDirection="row-reverse">
            <Stack direction="row" spacing={4}>
              <Link to={AppRouts.WEBSITEREVIEW}>
                <Button type="button" variant="contained" color="primary">
                  Cancel
                </Button>
              </Link>
              <Button type="submit" variant="contained" color="primary">
                Submit
              </Button>
            </Stack>
          </Box>
        </form>
      </div>
    </div>
  );
};

export default AddWebsiteReview;
