import React, { useState, useEffect } from "react";
import SubHeader from "./SubHeader";
import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  Stack,
  Switch,
  Typography,
} from "@mui/material";
import CustomTextField from "./CustomTextField";
import { Link } from "react-router-dom";
import { AppRouts, TableName } from "./Constant";
import { useFormik } from "formik";
import { useNavigate, useParams } from "react-router";
import * as yup from "yup";
import useSupabase from "./utils/useSupabase";
import { showToast } from "./toastUtils";
import ImageUpload from "./ImageUpload";
import { removeImage, uploadImage } from "./Helperfunction";
import { StorageBucket } from "./Constant";

const AddCategory = () => {
  const supabase = useSupabase();
  const navigate = useNavigate();
  const params = useParams();
  const [imagesPreview, setImagesPreview] = useState([]);
  const [isNewImageUp, setIsNewImageUp] = useState(false);
  const [originalImage, setOriginalImage] = useState();

  const initialValues = {
    code: "",
    name: "",
    isActive: false,
  };

  const validationSchema = yup.object().shape({
    code: yup.string()
      .required("Category Code is required")
      .max(10)
      .test(
        "code-exists",
        "Category code already exists",
        async function (value) {
          const categoryId = params.id;
          if (categoryId) {
            return true;
          }

          try {
            const { data: existingData, error: existingError } = await supabase
              .from(TableName.CATEGORY)
              .select("id")
              .eq("categoryCode", value);

            if (existingError) {
              console.error(
                "Error checking existing code:",
                existingError.message
              );
              return false;
            }

            return !(existingData && existingData.length > 0);
          } catch (error) {
            console.error("Error:", error.message);
            return false;
          }
        }
      ),
      name: yup.string()
      .required("category Name is required")
      .max(20)
      .test(
        "code-exists",
        " Category name already exists",
        async function (value) {
          const categoryId = params.id;
          if (categoryId) {
            return true;
          }

          try {
            const { data: existingData, error: existingError } = await supabase
              .from(TableName.CATEGORY)
              .select("id")
              .eq("categoryName", value);

            if (existingError) {
              console.error(
                "Error checking existing name:",
                existingError.message
              );
              return false;
            }

            return !(existingData && existingData.length > 0);
          } catch (error) {
            console.error("Error:", error.message);
            return false;
          }
        }
      ),
  });
  useEffect(
    () => {
      const fetchCategoryData = async () => {
        const categoryId = params.id;
        const { data, error } = await supabase
          .from(TableName.CATEGORY)
          .select()
          .eq("id", categoryId);

        if (error) {
          console.error("Error fetching category data:", error.message);
        } else if (data && data.length > 0) {
          const category = data[0];
          formik.setValues({
            code: category.categoryCode,
            name: category.categoryName,
            isActive: category.isActive,
          });
          if (category.imageUrl) {
            setImagesPreview([{ imageUrl: category.imageUrl }]);
          }
          setOriginalImage(category.imageUrl);
        }
      };
      if (params.id) {
        fetchCategoryData();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [params.id, supabase]
  );
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: async (values, event) => {
      console.log(values,"bb");
      if (params.id) {
        let publicUrl = originalImage;
        if (isNewImageUp) {
          await removeImage(StorageBucket.CATEGORY, originalImage);
          publicUrl = await uploadImage(
            StorageBucket.CATEGORY,
            imagesPreview[0].imageFile
          );
        }

        const { data, error } = await supabase
          .from(TableName.CATEGORY)
          .update([
            {
              categoryCode: values.code,
              categoryName: values.name,
              isActive: values.isActive,
              imageUrl: publicUrl,
            },
          ])
          .eq("id", params.id);
        if (!error) {
          console.log("category data update", data);
          navigate(AppRouts.CATEGORY);
          showToast("update", "Course Category");
        }
      } else {
        try {
          let publicUrl = "";
          if (imagesPreview.length > 0) {
            publicUrl = await uploadImage(
              StorageBucket.CATEGORY,
              imagesPreview[0].imageFile
            );
          }
          const { error: insertError } = await supabase
            .from(TableName.CATEGORY)
            .insert([
              {
                categoryCode: values.code,
                categoryName: values.name,
                isActive: values.isActive,
                imageUrl: publicUrl,
              },
            ]);

          if (insertError) {
            console.error("Error inserting data:", insertError.message);
            return;
          }

          navigate(AppRouts.CATEGORY);
          showToast("success", "Course Category");
        } catch (error) {
          console.error("Error:", error.message);
        }
      }
    },
  });

  const handleImageUpload = async (event) => {
    if (event?.target?.files?.length > 0) {
      const imageUrl = URL.createObjectURL(event?.target?.files[0]);
      setImagesPreview([
        {
          imageFile: event?.target?.files[0],
          imageUrl: imageUrl,
        },
      ]);
      setIsNewImageUp(true);
      setOriginalImage(imageUrl);  
    }
  };

  const handleCancelImage = (index) => {
    setImagesPreview([]);
    setOriginalImage(null);  
  };

  return (
    <div>
      <SubHeader
        title={
          params.id ? "Edit a Course Category " : "Add a new Course Category"
        }
        subtitle="Category"
        back="Category"
        linkProps={{
          to: "/category",
        }}
      />
      <div style={{ padding: "35px" }}>
        <form onSubmit={formik.handleSubmit}>
          <Box
            style={{
              padding: "20px",
              textAlign: "left",
              boxShadow: "0.5px 0.5px 5px 0.5px rgb(223, 218, 218)",
              borderRadius: "20px",
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} md={5}>
                <Typography variant="h6" sx={{ fontWeight: "400" }}>
                  Category Details
                  <Typography sx={{ fontSize: "13px", color: "#a3a3b8" }}>
                    Please provide Course Category details
                  </Typography>
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Grid container>
                  <Grid item xs={12}>
                    <div style={{ margin: 17 }}>
                      <ImageUpload
                        isMultiImages={false}
                        handleImageUpload={handleImageUpload}
                        handleCancelImage={handleCancelImage}
                        uploadedImages={imagesPreview}
                        title="Click to upload category Image"
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <InputLabel>
                      <Typography sx={{ color: "black", fontWeight: "500" }}>
                        Category Code
                        <Typography sx={{ color: "red" }} component="span">
                          *
                        </Typography>
                      </Typography>
                    </InputLabel>
                    <CustomTextField
                      name="code"
                      value={formik.values.code}
                      onChange={(e) => {
                        const { value } = e.target;
                        if (
                          /^[a-zA-Z0-9]*$/.test(value) &&
                          value.length <= 10
                        ) {
                          formik.handleChange(e);
                        }
                      }}
                      onBlur={formik.handleBlur}
                      error={formik.touched.code && Boolean(formik.errors.code)}
                    />
                    {formik.touched.code && formik.errors.code && (
                      <Typography
                        sx={{
                          color: "#d32f2f",
                          fontSize: "0.75rem",
                          mt: "-10px",
                        }}
                      >
                        {formik.errors.code}
                      </Typography>
                    )}
                  </Grid>
                  <br />
                  <Grid item xs={12}>
                    <InputLabel>
                      <Typography sx={{ color: "black", fontWeight: "500" }}>
                        Category Name
                        <Typography sx={{ color: "red" }} component="span">
                          *
                        </Typography>
                      </Typography>
                    </InputLabel>
                    <CustomTextField
                      name="name"
                      value={formik.values.name}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={formik.touched.name && Boolean(formik.errors.name)}
                    />
                    {formik.touched.name && formik.errors.name && (
                      <Typography
                        sx={{
                          color: "#d32f2f",
                          fontSize: "0.75rem",
                          mt: "-10px",
                        }}
                      >
                        {formik.errors.name}
                      </Typography>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
          <br />
          <br />
          <Box
            style={{
              padding: "20px",
              textAlign: "left",
              boxShadow: "0.5px 0.5px 5px 0.5px rgb(223, 218, 218)",
              borderRadius: "20px",
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} md={5}>
                <Typography variant="h6" sx={{ fontWeight: "400" }}>
                  Category Status
                  <Typography sx={{ fontSize: "13px", color: "#a3a3b8" }}>
                    Please provide your course category status
                  </Typography>
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Grid container>
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <Stack direction="row">
                        <Typography>Active</Typography>
                        <Switch
                          name="isActive"
                          color="primary"
                          checked={formik.values.isActive}
                          onChange={formik.handleChange}
                        />
                      </Stack>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
          <Box pt={3} display="flex" flexDirection="row-reverse">
            <Stack direction="row" spacing={4}>
              <Link to={AppRouts.CATEGORY}>
                <Button type="cancle" variant="contained" color="primary">
                  Cancel
                </Button>
              </Link>
              <Button type="submit" variant="contained" color="primary">
                Submit
              </Button>
            </Stack>
          </Box>
        </form>
      </div>
    </div>
  );
};

export default AddCategory;
