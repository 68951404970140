import React from "react";
import SubHeader from "./SubHeader";
import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Switch,
  Typography,
} from "@mui/material";

import { useState } from "react";
import CustomTextField from "./CustomTextField";
import { Link } from "react-router-dom";
import useSupabase from "./utils/useSupabase";
import { useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { AppRouts, TableName } from "./Constant";
import { useNavigate, useParams } from "react-router";
import ImageUpload from "./ImageUpload";
import { removeImage, uploadImage } from "./Helperfunction";
import { StorageBucket } from "./Constant";
import CircularProgress from "@mui/material/CircularProgress";
import { showToast } from "./toastUtils";

const AddSubcategory = () => {
  const supabase = useSupabase();
  const navigate = useNavigate();
  const params = useParams();

  const [category, setCategory] = useState([]);
  const [isLoading, setisLoading] = useState(false);
  const [imagesPreview, setImagesPreview] = useState([]);
  const [isNewImageUp, setIsNewImageUp] = useState(false);
  const [originalImage, setOriginalImage] = useState();

  const validationSchema = Yup.object().shape({
    categoryName: Yup.string().required("Category Name is required"),
    subcategoryCode: Yup.string()
      .required("Subcategory Code is required")
      .max(20)
      .test(
        "code-exists",
        "SubCategory code already exists",
        async function (value) {
          const subcategoryId = params.id;
          if (subcategoryId) {
            return true;
          }

          try {
            const { data: existingData, error: existingError } = await supabase
              .from(TableName.SUBCATEGORY)
              .select("id")
              .eq("subcategoryCode", value);

            if (existingError) {
              console.error(
                "Error checking existing code:",
                existingError.message
              );
              return false;
            }

            return !(existingData && existingData.length > 0);
          } catch (error) {
            console.error("Error:", error.message);
            return false;
          }
        }
      ),
    subcategoryName: Yup.string()
      .required("Subcategory Name is required")
      .max(50)
      .test(
        "code-exists",
        " SubCategory name already exists",
        async function (value) {
          const subcategoryId = params.id;
          if (subcategoryId) {
            return true;
          }

          try {
            const { data: existingData, error: existingError } = await supabase
              .from(TableName.SUBCATEGORY)
              .select("id")
              .eq("subcategoryName", value);

            if (existingError) {
              console.error(
                "Error checking existing name:",
                existingError.message
              );
              return false;
            }

            return !(existingData && existingData.length > 0);
          } catch (error) {
            console.error("Error:", error.message);
            return false;
          }
        }
      ),
  });

  useEffect(
    () => {
      const fetchSubcategoryData = async () => {
        const subcategoryId = params.id;
        const { data, error } = await supabase
          .from(TableName.SUBCATEGORY)
          .select()
          .eq("id", subcategoryId);

        if (error) {
          console.error("Error fetching subcategory data:", error.message);
        } else if (data && data.length > 0) {
          const subcategory = data[0];
          formik.setValues({
            categoryName: subcategory.categoryName,
            subcategoryCode: subcategory.subcategoryCode,
            subcategoryName: subcategory.subcategoryName,

            isActive: subcategory.isActive,
          });
          if (subcategory.imageUrl) {
            setImagesPreview([{ imageUrl: subcategory.imageUrl }]);
          }
          setOriginalImage(subcategory.imageUrl);
        }
      };

      if (params.id) {
        fetchSubcategoryData();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [params.id, supabase]
  );
  const formik = useFormik({
    initialValues: {
      categoryName: "",
      subcategoryCode: "",
      subcategoryName: "",
      isActive: false,
    },
    validationSchema: validationSchema,
    onSubmit: async (values, event) => {
      if (params.id) {
        let publicUrl = originalImage;
        if (isNewImageUp) {
          await removeImage(StorageBucket.SUBCATEGORY, originalImage);
          publicUrl = await uploadImage(
            StorageBucket.SUBCATEGORY,
            imagesPreview[0].imageFile
          );
        }
        const { data, error } = await supabase
          .from(TableName.SUBCATEGORY)
          .update([
            {
              categoryName: values.categoryName,
              subcategoryCode: values.subcategoryCode,
              subcategoryName: values.subcategoryName,
              isActive: values.isActive,
              imageUrl: publicUrl,
            },
          ])
          .eq("id", params.id);
        if (!error) {
          console.log("category data update", data);
          setisLoading(false);
          navigate(AppRouts.SUBCATEGORY);
          showToast("update", "Course Subcategory");
        }
      } else {
        try {
          let publicUrl = "";
          if (imagesPreview.length > 0) {
            publicUrl = await uploadImage(
              StorageBucket.SUBCATEGORY,
              imagesPreview[0].imageFile
            );
          }
          const { error: insertError } = await supabase
            .from(TableName.SUBCATEGORY)
            .insert([
              {
                categoryName: values.categoryName,
                subcategoryCode: values.subcategoryCode,
                subcategoryName: values.subcategoryName,
                isActive: values.isActive,
                imageUrl: publicUrl,
              },
            ]);

          if (insertError) {
            console.error("Error inserting data:", insertError.message);
            return;
          }
          setisLoading(false);
          navigate(AppRouts.SUBCATEGORY);
          showToast("success", "Course Subcategory");
        } catch (error) {
          console.error("Error:", error.message);
        }
      }
    },
  });
  useEffect(
    () => {
      getcategory();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  const getcategory = async () => {
    const { data: categoryData, error: categoryError } = await supabase
      .from(TableName.CATEGORY)
      .select()
      .eq("isActive", true);
    if (categoryError) {
      console.error("Error fetching categories:", categoryError);
      return;
    }

    setCategory(categoryData);
  };

  const handleImageUpload = async (event) => {
    if (event?.target?.files?.length > 0) {
      const imageUrl = URL.createObjectURL(event?.target?.files[0]);
      setImagesPreview([
        {
          imageFile: event?.target?.files[0],
          imageUrl: imageUrl,
        },
      ]);
      setIsNewImageUp(true);
      setOriginalImage(imageUrl);
    }
  };

  const handleCancelImage = (index) => {
    setImagesPreview([]);
    setOriginalImage(null);
  };

  return (
    <div>
      <SubHeader
        title={
          params.id
            ? "Edit a Course Subcategory "
            : "Add a new Course Subcategory"
        }
        subtitle="Subcategory"
        back="Subcategory"
        linkProps={{
          to: "/subcategory",
        }}
      />
      <div style={{ padding: "27px" }}>
        <form onSubmit={formik.handleSubmit}>
          <Box
            style={{
              padding: "20px",
              textAlign: "left",
              boxShadow: "0.5px 0.5px 5px 0.5px rgb(223, 218, 218)",
              borderRadius: "20px",
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} md={5}>
                <Typography variant="h6" sx={{ fontWeight: "400" }}>
                  Subcategory Details
                  <Typography sx={{ fontSize: "13px", color: "#a3a3b8" }}>
                    Please provide course subcategory details
                  </Typography>
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Grid container>
                  <Grid item xs={12}>
                    <div style={{ margin: 17 }}>
                      <ImageUpload
                        isMultiImages={false}
                        handleImageUpload={handleImageUpload}
                        handleCancelImage={handleCancelImage}
                        uploadedImages={imagesPreview}
                        title="Click to upload subcategory Image"
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <InputLabel>
                      <Typography
                        sx={{
                          color: "black",
                          fontWeight: "500",
                        }}
                      >
                        Category
                        <Typography sx={{ color: "red" }} component="span">
                          *
                        </Typography>
                      </Typography>
                      <FormControl fullWidth size="small" variant="outlined">
                        <Select
                          name="categoryName"
                          value={formik.values.categoryName}
                          onChange={formik.handleChange}
                          disabled={!!params.id}
                          error={
                            formik.touched.categoryName &&
                            Boolean(formik.errors.categoryName)
                          }
                          sx={{
                            width: "80%",
                            height: "40px",
                          }}
                        >
                          {category.map((category) => (
                            <MenuItem
                              key={category.id}
                              value={category.categoryCode}
                            >
                              {category.categoryName}
                            </MenuItem>
                          ))}
                        </Select>
                        {formik.touched.categoryName &&
                          formik.errors.categoryName && (
                            <Typography
                              color="#d32f2f"
                              style={{
                                fontSize: "0.75rem",
                              }}
                            >
                              {formik.errors.categoryName}
                            </Typography>
                          )}
                      </FormControl>
                    </InputLabel>
                  </Grid>
                  <Grid item xs={12}>
                    <InputLabel>
                      <Typography
                        sx={{
                          color: "black",
                          fontWeight: "500",
                          pt: "10px",
                        }}
                      >
                        Subcategory Code
                        <Typography sx={{ color: "red" }} component="span">
                          *
                        </Typography>
                      </Typography>
                    </InputLabel>
                    <CustomTextField
                      name="subcategoryCode"
                      value={formik.values.subcategoryCode}
                      onChange={(e) => {
                        const { value } = e.target;
                        if (
                          /^[a-zA-Z0-9]*$/.test(value) &&
                          value.length <= 50
                        ) {
                          formik.handleChange(e);
                        }
                      }}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.subcategoryCode &&
                        Boolean(formik.errors.subcategoryCode)
                      }
                      maxLength={10}
                      disabled={!!params.id}
                    />
                    {formik.touched.subcategoryCode &&
                      formik.errors.subcategoryCode && (
                        <Typography
                          sx={{
                            color: "#d32f2f",
                            fontSize: "0.75rem",
                            mt: "-10px",
                          }}
                        >
                          {formik.errors.subcategoryCode}
                        </Typography>
                      )}
                  </Grid>
                  <Grid item xs={12}>
                    <InputLabel>
                      <Typography
                        sx={{
                          //mt: "8px",
                          color: "black",
                          fontWeight: "500",
                        }}
                      >
                        Subcategory Name
                        <Typography sx={{ color: "red" }} component="span">
                          *
                        </Typography>
                      </Typography>
                    </InputLabel>
                    <CustomTextField
                      name="subcategoryName"
                      value={formik.values.subcategoryName}
                      onChange={(e) => {
                        const { value } = e.target;
                        const capitalizedValue =
                          value.charAt(0).toUpperCase() + value.slice(1);
                        if (capitalizedValue.length <= 50) {
                          formik.handleChange({
                            target: {
                              name: "subcategoryName",
                              value: capitalizedValue,
                            },
                          });
                        }
                      }}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.subcategoryName &&
                        Boolean(formik.errors.subcategoryName)
                      }
                    />

                    {formik.touched.subcategoryName &&
                      formik.errors.subcategoryName && (
                        <Typography
                          sx={{
                            color: "#d32f2f",
                            fontSize: "0.75rem",
                            mt: "-10px",
                          }}
                        >
                          {formik.errors.subcategoryName}
                        </Typography>
                      )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
          <br />
          <br />

          <Box
            style={{
              padding: "20px",
              textAlign: "left",
              boxShadow: "0.5px 0.5px 5px 0.5px rgb(223, 218, 218)",
              borderRadius: "20px",
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} md={5}>
                <Typography variant="h6" sx={{ fontWeight: "400" }}>
                  Subcategory Status
                  <Typography sx={{ fontSize: "13px", color: "#a3a3b8" }}>
                    Please provide your course subcategory status
                  </Typography>
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Grid container>
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <Stack direction="row">
                        <Typography>Active</Typography>
                        <Switch
                          name="isActive"
                          checked={formik.values.isActive}
                          onChange={formik.handleChange}
                          color="primary"
                        />
                      </Stack>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
          <Box pt={3} display="flex" flexDirection="row-reverse">
            <Stack direction="row" spacing={4}>
              <Link to={AppRouts.SUBCATEGORY}>
                <Button type="cancle" variant="contained" color="primary">
                  Cancel
                </Button>
              </Link>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={isLoading}
              >
                {isLoading ? (
                  <CircularProgress color="primary" className="load" />
                ) : (
                  "Submit"
                )}
              </Button>
            </Stack>
          </Box>
        </form>
      </div>
    </div>
  );
};

export default AddSubcategory;
