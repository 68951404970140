import React from "react";
import { TextField, Typography } from "@mui/material";

const CustomTextField = ({
  label,
  name,
  value,
  onChange,
  onBlur,
  placeholder,
  type,
  ...restProps
}) => {
  const handleScroll = (event) => {
    if (type === "number") {
      event.preventDefault();
    }
  };
  return (
    <div>
      <TextField
        size="small"
        //required
        fullWidth
        label={label}
        name={name}
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        onBlur={onBlur}
        sx={{ width: "80%", marginBottom: 1, borderRadius: "20px" }}
        InputProps={{
          onWheel: handleScroll, // Prevent scrolling
        }}
        {...restProps}
      />
      <Typography sx={{ color: "red", fontSize: 12, marginTop: 1 }}>
        {restProps.required}
      </Typography>
    </div>
  );
};

export default CustomTextField;
