import React, { useState, useEffect } from "react";
import { Grid, Typography, Fab, IconButton } from "@mui/material";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import CancelIcon from "@mui/icons-material/Cancel";

const ImageUpload = ({
  isMultiImages,
  handleImageUpload,
  uploadedImages,
  handleCancelImage,
  title,
  inputRef,
}) => {
  const [imagesPreview, setImagesPreview] = useState([]);
  const [showUploadIcon, setShowUploadIcon] = useState(true);

  useEffect(() => {
    if (uploadedImages?.length > 0) {
      setImagesPreview(uploadedImages);

      if (!isMultiImages) {
        setShowUploadIcon(false);
      }
    } else {
      setImagesPreview([]);
      setShowUploadIcon(true);
    }
  }, [uploadedImages, isMultiImages]);

  const handleRemoveImage = (index) => {
    const updatedImages = imagesPreview.filter((_, i) => i !== index);
    setImagesPreview(updatedImages);
    handleCancelImage(index);

    if (updatedImages.length === 0) {
      setShowUploadIcon(true);
    }
  };

  return (
    <Grid xs={12} md={8} item>
      <label htmlFor="image-upload" className="upload-label">
        <input
          ref={inputRef}
          type="file"
          id="image-upload"
          accept="image/*"
          style={{ display: "none" }}
          onChange={handleImageUpload}
          multiple={isMultiImages}
        />
        {showUploadIcon && (
          <div style={{ marginLeft: "130px" }}>
            <Fab component="span">
              <FileUploadIcon sx={{ width: 40, height: 67 }} />
            </Fab>
            <Typography className="click" sx={{ marginLeft: "-80px" }}>
              {title}
            </Typography>
          </div>
        )}
      </label>
      <br />
      <div className="photos">
        {imagesPreview?.map((image, index) => (
          <div key={index} className="image-card">
            <img
              src={image.imageUrl}
              alt={`Uploaded ${index + 1}`}
              className="uploaded-image"
              style={{
                width: 200,
                height: 200,
                borderRadius: 22,
                marginTop: "5px",
                marginLeft: "80px",
              }}
            />
            <IconButton onClick={() => handleRemoveImage(index)}>
              <CancelIcon />
            </IconButton>
          </div>
        ))}
      </div>
    </Grid>
  );
};

export default ImageUpload;
