import React, { useState, useEffect } from "react";
import {
  Container,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  InputAdornment,
  IconButton,
  TableContainer,
  TablePagination,
  Box,
  Divider,
} from "@mui/material";
import { Search } from "@mui/icons-material";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { Link } from "react-router-dom";
import useSupabase from "./utils/useSupabase.jsx";
import Header from "./Header";
import Tooltip from "@mui/material/Tooltip";
import SelectedFilter from "./SelectedFilter";
import CommonFilter from "./CommonFilter";
import { AppRouts, TableName } from "./Constant";

const CertificationTraining = () => {
    const [searchTerm, setSearchTerm] = useState("");
    const [filter, setFilter] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(4);
    const [totalItems, setTotalItems] = useState(0);
    const [categories, setCategories] = useState([]);
    const supabase = useSupabase();
  
    useEffect(
      () => {
        fetchTrainingData(0, searchTerm);
      },
      //eslint-disable-next-line react-hooks/exhaustive-deps
      [filter]
    );
    async function fetchTrainingData(newPage, searchKey) {
      try {
        let query = supabase
          .from(TableName.CERTIFICATION)
          .select("*", { count: "exact" });
        if (searchKey.trim() !== "") {
          query = query.ilike("name", `%${searchKey}%`);
        }
        query = query.order("name");
        if (filter.length > 0) {
          query = query.in(
            "isActive",
            filter.map((item) => item.code === "yes")
          );
        }
  
        const { data, count, error } = await query
          .range(newPage * rowsPerPage, (newPage + 1) * rowsPerPage - 1)
          .limit(rowsPerPage);
  
        console.log("Total Items:", count);
  
        setCategories(data || []);
        setTotalItems(count || 0);
  
        if (error) {
          console.error("Error fetching categories:", error);
        }
      } catch (error) {
        console.error("Error performing search:", error);
      }
    }
  
    const handleSearch = async (event) => {
      setSearchTerm(event.target.value);
      if (searchTerm === "") {
        setPage(0);
      }
      setTimeout(() => {
        fetchTrainingData(0, event.target.value);
      }, 500);
    };
    const handleChangePage = (event, newPage) => {
        fetchTrainingData(newPage, searchTerm);
      setPage(newPage);
    };
  
    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(parseInt(event.target.value, 4));
      setPage(0);
    };
  
    const handleFilterChange = (selectedItems) => {
      setFilter(selectedItems);
      setPage(0);
    };
    return (
      <div style={{ padding: "20px" }}>
        <Container>
          <Header
            title="Certification Training"
            subtitle="Certification Training"
            subtitle1="List"
            showAddButton={true}
            linkProps={{
              to: AppRouts.ADDTRAINING_WITHOUT_ID,
            }}
          />
          <div className="topContent">
            <Grid item lg={12} xs={12} sm={6} md={6}>
              <TextField
                sx={{ p: "7px" }}
                id="standard-search"
                type="search"
                variant="standard"
                fullWidth
                placeholder="Search by Certification name"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  ),
                  disableUnderline: true,
                }}
                onChange={handleSearch}
              />
            </Grid>
            <Grid item xs={12} lg={12} sm={12} md={12}>
              <Divider className="divider" />
            </Grid>
            <SelectedFilter
              selectedOptions={filter}
              onFilterChange={handleFilterChange}
            />
            <Grid item xs={12} lg={12} sm={12} md={12}>
              <Divider className="divider" />
            </Grid>
            <CommonFilter
              id="isactive"
              title="Active"
              options={[
                { code: "yes", value: "Yes" },
                { code: "no", value: "No" },
              ]}
              selectedOptions={filter}
              onFilterChange={handleFilterChange}
            />
          </div>
  
          <Grid item xs={12} lg={12} sm={12} md={12}>
            <TableContainer
              style={{ width: "100.5%" }}
              sx={{ boxShadow: "0px 0.5px 1px 0.5px rgb(223, 218, 218)" }}
            >
              <Table sx={{ minWidth: 650, borderBottom: "none" }}>
                <TableHead>
                  <TableRow sx={{ backgroundColor: "#F5F5F5" }}>
                    <TableCell
                      align="center"
                      className="cell"
                      sx={{ color: "rgb(47, 55, 70)", fontWeight: "bold" }}
                    >
                      Code
                    </TableCell>
                    <TableCell
                      align="center"
                      className="cell"
                      sx={{ color: "rgb(47, 55, 70)", fontWeight: "bold" }}
                    >
                      Name
                    </TableCell>
  
                    <TableCell
                      align="center"
                      className="cell"
                      sx={{ color: "rgb(47, 55, 70)", fontWeight: "bold" }}
                    >
                      Active
                    </TableCell>
                    <TableCell
                      align="center"
                      className="cell"
                      sx={{ color: "rgb(47, 55, 70)", fontWeight: "bold" }}
                    >
                      Action
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {categories
                    .filter((category) => {
                      const categoryName = category.name?.toLowerCase();
                      const searchTermLower = searchTerm?.toLowerCase();
                      return categoryName?.includes(searchTermLower);
                    })
                    // .slice(page * rowsPerPage, (page + 1) * rowsPerPage)
                    .map((category, id) => (
                      <TableRow key={id}>
                        <TableCell sx={{ textAlign: "center", lineHeight: "1" }}>
                          {category.code}
                        </TableCell>
                        <TableCell sx={{ textAlign: "center", lineHeight: "1" }}>
                          {category.name}
                        </TableCell>
  
                        <TableCell sx={{ textAlign: "center", lineHeight: "1" }}>
                          {category.isActive ? "Yes" : "No"}
                        </TableCell>
                        <TableCell sx={{ textAlign: "center", lineHeight: "1" }}>
                          <Link
                            to={{
                              pathname: `${AppRouts.TRAINING}/${category.id}`,
                              state: { category: category },
                            }}
                          >
                            <Tooltip title="Edit" placement="right">
                              <IconButton variant="contained">
                                <EditOutlinedIcon />
                              </IconButton>
                            </Tooltip>
                          </Link>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Box className="page">
              <TablePagination
                component="div"
                count={totalItems}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                rowsPerPageOptions={[]}
                sx={{
                  "& .MuiPaginationItem-root": {
                    fontSize: "12px",
                  },
                  "& .Mui-selected": {
                    backgroundColor: "#1e88e5",
                    color: "rgb(108, 115, 127)",
                    "&:hover": {
                      backgroundColor: "#1565c0",
                    },
                  },
                  "& .MuiTablePagination-displayedRows": {
                    fontSize: "13px",
                    color: "rgb(108, 115, 127)",
                  },
                }}
              />
            </Box>
          </Grid>
        </Container>
      </div>
    );
  };

export default CertificationTraining;
