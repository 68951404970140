import React, { useEffect, useState } from "react";
import SubHeader from "./SubHeader";
import { Link, useParams } from "react-router-dom";
import { AppRouts, StorageBucket, TableName } from "./Constant";
import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  Stack,
  Switch,
  TextField,
  Typography,
  CircularProgress,
} from "@mui/material";
import CustomTextField from "./CustomTextField";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import useSupabase from "./utils/useSupabase";
import { showToast } from "./toastUtils";
import VideoUpload from "./VideoUpload";
import { removeImage, uploadImage } from "./Helperfunction";

const AddTestimonials = () => {
  const navigate = useNavigate();
  const supabase = useSupabase();
  const params = useParams();
  const [videosPreview, setVideosPreview] = useState([]);
  const [originalVideo, setOriginalVideo] = useState();
  const [isNewVideoUp, setIsNewVideoUp] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isLinkFieldDisabled, setIsLinkFieldDisabled] = useState(false);
  const [isVideoUploadDisabled, setIsVideoUploadDisabled] = useState(false);

  const initialValues = {
    name: "",
    review: "",
    link: "",
    isActive: false,
  };

  const validationSchema = yup.object({
    name: yup.string().required("Name is required"),
    review: yup.string().required("Review is required"),
  });

  useEffect(
    () => {
      const fetchReviewData = async () => {
        const reviewId = params.id;
        const { data, error } = await supabase
          .from(TableName.TESTIMONIALS)
          .select()
          .eq("id", reviewId);

        if (error) {
          console.error("Error fetching review data:", error.message);
        } else if (data && data.length > 0) {
          const review = data[0];
          formik.setValues({
            name: review.name,
            review: review.review,
            link: review.link,
            isActive: review.isActive,
          });
          if (review.videoUrl) {
            setVideosPreview([{ videoUrl: review.videoUrl }]);
          }
          setOriginalVideo(review.videoUrl);
        }
      };

      if (params.id) {
        fetchReviewData();
      }
    },
    //eslint-disable-next-line react-hooks/exhaustive-deps
    [params.id, supabase]
  );

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setLoading(true);
      const firstLetter = values.name.charAt(0).toUpperCase();
      let publicUrl = originalVideo;

      if (isNewVideoUp && originalVideo) {
        await removeImage(StorageBucket.WEBSITEREVIEW, originalVideo);
      }

      if (isNewVideoUp) {
        publicUrl = await uploadImage(
          StorageBucket.WEBSITEREVIEW,
          videosPreview[0].imageFile
        );
      }

      if (params.id) {
        const { error } = await supabase
          .from(TableName.TESTIMONIALS)
          .update({
            name: values.name,
            review: values.review,
            isActive: values.isActive,
            firstLetter: firstLetter,
            videoUrl: publicUrl,
            link: values.link,
          })
          .eq("id", params.id);

        if (!error) {
          navigate(AppRouts.TESTIMONIALS);
          showToast("update", "Testimonials");
        } else {
          console.error("Error updating data:", error.message);
        }
      } else {
        const { error: insertError } = await supabase
          .from(TableName.TESTIMONIALS)
          .insert([
            {
              name: values.name,
              review: values.review,
              isActive: values.isActive,
              firstLetter: firstLetter,
              videoUrl: publicUrl,
              link: values.link,
            },
          ]);

        if (!insertError) {
          navigate(AppRouts.TESTIMONIALS);
          showToast("success", "Testimonials");
        } else {
          console.error("Error inserting data:", insertError.message);
        }
      }
      setLoading(false);
    },
  });

  const handleVideoUpload = (event) => {
    if (event?.target?.files?.length > 0) {
      const videoUrl = URL.createObjectURL(event?.target?.files[0]);
      setVideosPreview([
        {
          imageFile: event?.target?.files[0],
          videoUrl: videoUrl,
        },
      ]);
      setIsNewVideoUp(true);
      setOriginalVideo(videoUrl);
      setIsLinkFieldDisabled(true);
      formik.setFieldValue("link", ""); // Clear the link field
    }
  };

  const handleCancelVideo = () => {
    setVideosPreview([]);
    setOriginalVideo(null);
    setIsLinkFieldDisabled(false);
  };

  const handleLinkChange = (event) => {
    formik.handleChange(event);
    if (event.target.value) {
      setIsVideoUploadDisabled(true);
      setVideosPreview([]);
      setIsNewVideoUp(false);
    } else {
      setIsVideoUploadDisabled(false);
    }
  };

  return (
    <div>
      <SubHeader
        title={params.id ? "Edit a Testimonial" : "Add a new Testimonial"}
        subtitle="Testimonial"
        back="Testimonials"
        linkProps={{
          to: AppRouts.TESTIMONIALS,
        }}
      />
      <div style={{ padding: "35px" }}>
        <form onSubmit={formik.handleSubmit}>
          <Box
            style={{
              padding: "20px",
              textAlign: "left",
              boxShadow: "0.5px 0.5px 5px 0.5px rgb(223, 218, 218)",
              borderRadius: "20px",
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} md={5}>
                <Typography variant="h6" sx={{ fontWeight: "400" }}>
                  Testimonial Video
                  <Typography sx={{ fontSize: "13px", color: "green" }}>
                    **Dear Admin You can either upload a video file or provide a
                    video link, but not both.Please select the option that best
                    suits your needs.**
                  </Typography>
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Grid container>
                  <Grid item xs={12}></Grid>
                  <br />
                  <VideoUpload
                    handleVideoUpload={handleVideoUpload}
                    handleCancelVideo={handleCancelVideo}
                    uploadedVideos={videosPreview}
                    title="Click to upload student video"
                    disabled={isVideoUploadDisabled}
                  />
                  <br />
                  <Grid item xs={12}>
                    <InputLabel>
                      <Typography sx={{ color: "black", fontWeight: "500" }}>
                        Video Link
                      </Typography>
                    </InputLabel>
                    <CustomTextField
                      name="link"
                      value={formik.values.link}
                      onChange={handleLinkChange}
                      InputProps={{
                        readOnly: isLinkFieldDisabled,
                      }}
                    />
                  </Grid>
                  <br />
                </Grid>
              </Grid>
            </Grid>
          </Box>
          <br />
          <br />
          <Box
            style={{
              padding: "20px",
              textAlign: "left",
              boxShadow: "0.5px 0.5px 5px 0.5px rgb(223, 218, 218)",
              borderRadius: "20px",
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} md={5}>
                <Typography variant="h6" sx={{ fontWeight: "400" }}>
                  Testimonial Basic Details
                  <Typography sx={{ fontSize: "13px", color: "#a3a3b8" }}>
                    Please provide your Testimonial basic details
                  </Typography>
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Grid container>
                  <Grid item xs={12}>
                    <Grid item xs={12}>
                      <InputLabel>
                        <Typography sx={{ color: "black", fontWeight: "500" }}>
                          Name
                          <Typography sx={{ color: "red" }} component="span">
                            *
                          </Typography>
                        </Typography>
                      </InputLabel>
                      <CustomTextField
                        name="name"
                        value={formik.values.name}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.name && Boolean(formik.errors.name)
                        }
                        helperText={formik.touched.name && formik.errors.name}
                      />
                    </Grid>
                    <br />
                    <Grid item xs={12}>
                      <InputLabel>
                        <Typography sx={{ color: "black", fontWeight: "500" }}>
                          Testimonial Description
                          <Typography sx={{ color: "red" }} component="span">
                            *
                          </Typography>
                        </Typography>
                      </InputLabel>
                      <TextField
                        name="review"
                        value={formik.values.review}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.review && Boolean(formik.errors.review)
                        }
                        sx={{ width: "80%" }}
                        id="outlined-multiline-static"
                        multiline
                        rows={4}
                      />
                    </Grid>

                    {formik.touched.review && formik.errors.review && (
                      <Typography
                        sx={{
                          color: "#d32f2f",
                          fontSize: "0.75rem",
                        }}
                      >
                        {formik.errors.review}
                      </Typography>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
          <br />
          <br />
          <Box
            style={{
              padding: "20px",
              textAlign: "left",
              boxShadow: "0.5px 0.5px 5px 0.5px rgb(223, 218, 218)",
              borderRadius: "20px",
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} md={5}>
                <Typography variant="h6" sx={{ fontWeight: "400" }}>
                  Testimonial Status
                  <Typography sx={{ fontSize: "13px", color: "#a3a3b8" }}>
                    Please provide your Testimonial status
                  </Typography>
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Grid container>
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <Stack direction="row">
                        <Typography>Active</Typography>
                        <Switch
                          name="isActive"
                          color="primary"
                          checked={formik.values.isActive}
                          onChange={formik.handleChange}
                        />
                      </Stack>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
          <Box pt={3} display="flex" flexDirection="row-reverse">
            <Stack direction="row" spacing={4}>
              <Link to={AppRouts.TESTIMONIALS}>
                <Button type="button" variant="contained" color="primary">
                  Cancel
                </Button>
              </Link>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={loading}
              >
                {loading ? <CircularProgress size={24} /> : "Submit"}
              </Button>
            </Stack>
          </Box>
        </form>
      </div>
    </div>
  );
};

export default AddTestimonials;
