import {
  Avatar,
  Card,
  Container,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Header from "./Header";
import { deepOrange } from "@mui/material/colors";
import EditNoteOutlinedIcon from "@mui/icons-material/EditNoteOutlined";
import { AppRouts } from "./Constant";
import useSupabase from "./utils/useSupabase";
import { Link } from "react-router-dom";

const WebsiteReview = () => {
  const [reviews, setReviews] = useState([]);
  const supabase = useSupabase();

  useEffect(() => {
    const fetchReviews = async () => {
      const { data, error } = await supabase.from("websiteReview").select("*");

      if (error) {
        console.error("Error fetching data:", error.message);
      } else {
        setReviews(data);
      }
    };

    fetchReviews();
  }, [supabase]);

  return (
    <>
      <div style={{ padding: "20px" }}>
        <Container className="ProductContainer">
          <Header
            title="Website Review"
            subtitle="Review"
            subtitle1="List"
            showAddButton={true}
            linkProps={{
              to: AppRouts.ADDWEBSITEREVIEW_WITHOUT_ID,
            }}
          />
          {reviews.map((review) => (
            <Card
              key={review.id}
              style={{
                width: "90%",
                marginTop: "25px",
                borderRadius: "15px",
                paddingTop: "25px",
                paddingLeft: "55px",
                marginLeft: "15px",
              }}
            >
              <Stack direction="row" spacing={2}>
                <Avatar
                  sx={{ bgcolor: deepOrange[500], width: 56, height: 56 }}
                >
                  {review.firstLetter || review.name.charAt(0).toUpperCase()}
                </Avatar>

                <Typography
                  variant="h5"
                  sx={{ font: "Inter", fontWeight: "700" }}
                >
                  {review.name}
                </Typography>
              </Stack>
              <Typography sx={{ font: "Inter", paddingLeft: "75px" }}>
                {review.review}
              </Typography>
              <Link to={`${AppRouts.WEBSITEREVIEW}/${review.id}`}>
                <IconButton sx={{ marginLeft: "auto", display: "flex" }}>
                  <EditNoteOutlinedIcon sx={{ fontSize: "40px" }} />
                </IconButton>
              </Link>
            </Card>
          ))}
        </Container>
      </div>
    </>
  );
};

export default WebsiteReview;
