import React, { useState } from "react";
import {
  Container,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Stack,
  Box,
  InputAdornment,
  IconButton,
  TableContainer,
  TablePagination,
  Divider,
} from "@mui/material";
import { Search } from "@mui/icons-material";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { Link } from "react-router-dom";
import useSupabase from "./utils/useSupabase";
import { useEffect } from "react";
import CommonFilter from "./CommonFilter";
import SelectedFilter from "./SelectedFilter";
import { AppRouts } from "./Constant.js";
import { TableName } from "./Constant.js";
import Header from "./Header";
import Tooltip from "@mui/material/Tooltip";

const Subcategory = () => {
  const supabase = useSupabase();
  const [totalCount, setTotalCount] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [subCategoryList, setSubCategoryList] = useState([]);
  const [page, setPage] = useState(0);
  const [category, setCategory] = useState([]);
  const [filter, setFilter] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(4);
  const getFilters = (id) => {
    return filter
      ?.filter((_data) => _data.id === id)
      ?.map((_data) => _data.code);
  };
  const fetchSubcategories = async (newPage, searchKey) => {
    const categoryName = getFilters("categoryName");
    const isActive = getFilters("isActive");
    let query = supabase
      .from(TableName.SUBCATEGORY)
      .select("*", { count: "exact" })
      .order("subcategoryName");

    if (categoryName && categoryName.length > 0) {
      query = query.in("categoryName", categoryName);
    }

    if (isActive && isActive.length > 0) {
      query = query.in("isActive", isActive);
    }
    if (searchKey.trim() !== "") {
      query = query.ilike("subcategoryName", `%${searchKey}%`);
    }
    const { data, count, error } = await query
      .range(newPage * rowsPerPage, (newPage + 1) * rowsPerPage - 1)
      .limit(rowsPerPage);
    setSubCategoryList(data || []);
    setTotalCount(count || 0);

    if (error) {
      console.error("Error fetching filtered subcategories:", error);
    } else {
      //console.log("Filtered Subcategory Data:", data);
    }
  };

  useEffect(
    () => {
      getcategory();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [searchTerm]
  );
  const getcategory = async () => {
    const { data: categoryData, error: categoryError } = await supabase
      .from(TableName.CATEGORY)
      .select()
      .eq("isActive", true);
    if (categoryError) {
      console.error("Error fetching categories:", categoryError);
      return;
    }

    setCategory(categoryData);
  };
  const handleSearch = async (event) => {
    setSearchTerm(event.target.value);
    if (searchTerm === "") {
      // If search term is empty, reset page to 0
      setPage(0);
    }
    setTimeout(() => {
      fetchSubcategories(0, event.target.value);
    }, 500);
  };
  const handleChangePage = (event, newPage) => {
    fetchSubcategories(newPage, searchTerm);
    setPage(newPage);
  };

  const handleFilterChange = (selectedItems) => {
    setFilter(selectedItems);
    setPage(0);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 4));
    setPage(0);
  };
  useEffect(
    () => {
      fetchSubcategories(0, searchTerm);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filter, searchTerm]
  );

  return (
    <div style={{ padding: "20px" }}>
      <Container className="ProductContainer">
        <Header
          title="Course SubCategory"
          subtitle="SubCategory"
          subtitle1="List"
          showAddButton={true}
          linkProps={{
            to: AppRouts.ADDSUBCATEGORY_WITHOUT_ID,
          }}
        />
        <div className="topContent">
          <Grid item lg={12} xs={12} sm={6} md={6}>
            <TextField
              sx={{ mt: "5px", p: "7px", ml: "-5px" }}
              id="standard-search"
              type="search"
              variant="standard"
              fullWidth
              placeholder="Search by subcategory name"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
                disableUnderline: true,
              }}
              value={searchTerm}
              onChange={handleSearch}
            />
          </Grid>
          <Grid item xs={12} lg={12} sm={12} md={12}>
            <Divider className="divider" />
          </Grid>
          <SelectedFilter
            selectedOptions={filter}
            onFilterChange={handleFilterChange}
          />
          <Grid item xs={12} lg={12} sm={12} md={12}>
            <Divider className="divider" />
          </Grid>
          <Stack direction="row">
            <CommonFilter
              id="categoryName"
              title="Category"
              options={category.map((category) => ({
                code: category.categoryCode,
                value: category.categoryName,
              }))}
              selectedOptions={filter}
              onFilterChange={handleFilterChange}
            />
            <CommonFilter
              id="isActive"
              title="Active"
              options={[
                { code: "Yes", value: "Yes" },
                { code: "No", value: "No" },
              ]}
              selectedOptions={filter}
              onFilterChange={handleFilterChange}
            />
          </Stack>
        </div>
        <Grid item xs={12} lg={12} sm={12} md={12}>
          <TableContainer
            style={{ width: "100.5%" }}
            sx={{ boxShadow: "0px 0.5px 1px 0.5px rgb(223, 218, 218)" }}
          >
            <Table sx={{ minWidth: 650, borderBottom: "none" }}>
              <TableHead>
                <TableRow sx={{ backgroundColor: "#F5F5F5" }}>
                  <TableCell
                    align="center"
                    className="cell"
                    sx={{ color: "rgb(47, 55, 70)", fontWeight: "bold" }}
                  >
                    Category Name
                  </TableCell>
                  <TableCell
                    align="center"
                    className="cell"
                    sx={{ color: "rgb(47, 55, 70)", fontWeight: "bold" }}
                  >
                    Subcategory Code
                  </TableCell>
                  <TableCell
                    align="center"
                    className="cell"
                    sx={{ color: "rgb(47, 55, 70)", fontWeight: "bold" }}
                  >
                    Subcategory Name
                  </TableCell>
                  <TableCell
                    align="center"
                    className="cell"
                    sx={{ color: "rgb(47, 55, 70)", fontWeight: "bold" }}
                  >
                    Active
                  </TableCell>
                  <TableCell
                    align="center"
                    className="cell"
                    sx={{ color: "rgb(47, 55, 70)", fontWeight: "bold" }}
                  >
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {subCategoryList
                  //.slice(page * rowsPerPage, (page + 1) * rowsPerPage)
                  .map((subcategory, id) => (
                    <TableRow key={id}>
                      <TableCell
                        style={{
                          color: "rgb(17, 25, 39)",
                        }}
                        align="center"
                      >
                        {subcategory.categoryName}
                      </TableCell>
                      <TableCell
                        sx={{ color: "rgb(17, 25, 39)" }}
                        align="center"
                      >
                        {subcategory.subcategoryCode}
                      </TableCell>
                      <TableCell
                        sx={{ color: "rgb(17, 25, 39)" }}
                        align="center"
                      >
                        {subcategory.subcategoryName}
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{ textAlign: "center", color: "rgb(17, 25, 39)" }}
                      >
                        {subcategory.isActive ? "Yes" : "No"}
                      </TableCell>
                      <TableCell align="center">
                        <Link
                          to={{
                            pathname: `${AppRouts.SUBCATEGORY}/${subcategory.id}`,
                            state: { subcategory: subcategory },
                          }}
                        >
                          <Tooltip title="Edit" placement="right">
                            <IconButton variant="contained">
                              <EditOutlinedIcon />
                            </IconButton>
                          </Tooltip>
                        </Link>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Box className="page">
            <TablePagination
              component="div"
              count={totalCount}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              rowsPerPageOptions={[]}
              sx={{
                "& .MuiPaginationItem-root": {
                  fontSize: "12px",
                },
                "& .Mui-selected": {
                  backgroundColor: "#1e88e5",
                  color: "rgb(108, 115, 127)",
                  "&:hover": {
                    backgroundColor: "#1565c0",
                  },
                },
                "& .MuiTablePagination-displayedRows": {
                  fontSize: "13px",
                  color: "rgb(108, 115, 127)",
                },
              }}
            />
          </Box>
        </Grid>
      </Container>
    </div>
  );
};

export default Subcategory;
