import React, { useEffect, useState } from "react";
import SubHeader from "./SubHeader";
import { useNavigate, useParams } from "react-router";
import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import CustomTextField from "./CustomTextField";
import { useFormik } from "formik";
import * as yup from "yup";
import { AppRouts, StorageBucket, TableName } from "./Constant";
import useSupabase from "./utils/useSupabase";
import { removeImage, uploadImage } from "./Helperfunction";
import ImageUpload from "./ImageUpload";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { Link } from "react-router-dom";
import { showToast } from "./toastUtils";

const AddWorkshop = () => {
  const days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const supabase = useSupabase();
  const navigate = useNavigate();
  const params = useParams();
  const [imagesPreview, setImagesPreview] = useState([]);
  const [originalImage, setOriginalImage] = useState();
  const [isNewImageUp, setIsNewImageUp] = useState(false);
  const [selectedWorkshopTime, setSelectedWorkshopTime] = useState(null);
  const [selectedworkshopDate, setSelectedworkshopDate] = useState(null);
  const handleworkshopDateChange = (date) => {
    setSelectedworkshopDate(date);
    formik.setFieldValue("workshopDate", date.add(1, "day"));
  };
  const handleWorkshopTimeChange = (time) => {
    setSelectedWorkshopTime(time);
    formik.setFieldValue("workshopTime", time);
  };
  const initialValues = {
    workshopCode: "",
    workshopName: "",
    workshopDesc: "",
    workshopDate: "",
    workshopDay: "",
    workshopTime: "",
    label: "",
    trainer: "",
    skills: "",
    realfees: "",
    fees: "",
    isActive: false,
  };
  const validationSchema = yup.object({
    workshopCode: yup.string().required("Workshop Code is required"),
    workshopName: yup.string().required("Workshop Name is required"),
    workshopDesc: yup.string().required("Workshop Description is required"),
    workshopDate: yup.string().required("Workshop date is required"),
    workshopDay: yup.string().required("Workshop Day is required"),
    workshopTime: yup.string().required("Workshop Time is required"),
    label: yup.string().required("Workshop label is required"),
    trainer: yup.string().required("Workshop Trainer Name is required"),
    skills: yup.string().required("Skills is required"),
    realfees: yup.string().required("Workshop Fees is required"),
    fees: yup.string().required("Workshop Fees is required"),
  });

  useEffect(
    () => {
      const fetchCourseData = async () => {
        const courseId = params.id;
        const { data, error } = await supabase
          .from(TableName.WORKSHOP)
          .select()
          .eq("id", courseId);

        if (error) {
          console.error("Error fetching course data:", error.message);
        } else if (data && data.length > 0) {
          const course = data[0];
          const dataGet = dayjs(course.workshopDate);
          setSelectedworkshopDate(dataGet);
          const timeget = dayjs(course.workshopTime);
          setSelectedWorkshopTime(timeget);
          formik.setValues({
            workshopCode: course.workshopCode,
            workshopName: course.workshopName,
            workshopDesc: course.workshopDesc,
            workshopDate: course.workshopDate,
            workshopDay: course.workshopDay,
            workshopTime: course.workshopTime,
            label: course.label,
            trainer: course.trainer,
            skills: course.skills,
            realfees: course.realfees,
            fees: course.fees,
            isActive: course.isActive,
          });
          if (course.imageUrl) {
            setImagesPreview([{ imageUrl: course.imageUrl }]);
          }
          setOriginalImage(course.imageUrl);
        }
      };

      if (params.id) {
        fetchCourseData();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [params.id, supabase]
  );
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: async (values, event) => {
      if (params.id) {
        let publicUrl = originalImage;
        if (isNewImageUp) {
          await removeImage(StorageBucket.COURSE, originalImage);
          publicUrl = await uploadImage(
            StorageBucket.WORKSHOP,
            imagesPreview[0].imageFile
          );
        }
        const { data, error } = await supabase
          .from(TableName.WORKSHOP)
          .update([
            {
              workshopCode: values.code,
              workshopName: values.name,
              workshopDesc: values.workshopDesc,
              workshopDate: values.workshopDate,
              workshopDay: values.workshopDay,
              workshopTime: values.workshopTime,
              realfees: values.realfees,
              fees: values.fees,
              label: values.label,
              trainer: values.trainer,
              skills: values.skills,
              isActive: values.isActive,
              imageUrl: publicUrl,
            },
          ])
          .eq("id", params.id);
        if (!error) {
          console.log("course data update", data);
          navigate(AppRouts.WORKSHOP);
          showToast("update", "Course Workshop");
        }
      } else {
        try {
          let publicUrl = "";
          if (imagesPreview.length > 0) {
            publicUrl = await uploadImage(
              StorageBucket.WORKSHOP,
              imagesPreview[0].imageFile
            );
          }
          const { error: insertError } = await supabase
            .from(TableName.WORKSHOP)
            .insert([
              {
                workshopCode: values.workshopCode,
                workshopName: values.workshopName,
                isActive: values.isActive,
                workshopDesc: values.workshopDesc,
                workshopDate: values.workshopDate,
                workshopDay: values.workshopDay,
                workshopTime: values.workshopTime,
                label: values.label,
                trainer: values.trainer,
                skills: values.skills,
                realfees: values.realfees,
                fees: values.fees,
                imageUrl: publicUrl,
              },
            ]);

          if (insertError) {
            console.error("Error inserting data:", insertError.message);
            return;
          }

          navigate(AppRouts.WORKSHOP);
          showToast("success", "Course Workshop");
        } catch (error) {
          console.error("Error:", error.message);
        }
      }
    },
  });
  const handleImageUpload = async (event) => {
    if (event?.target?.files?.length > 0) {
      const imageUrl = URL.createObjectURL(event?.target?.files[0]);
      setImagesPreview([
        {
          imageFile: event?.target?.files[0],
          imageUrl: imageUrl,
        },
      ]);
      setIsNewImageUp(true);
      setOriginalImage(imageUrl);
    }
  };

  const handleCancelImage = (index) => {
    setImagesPreview([]);
    setOriginalImage(null);
  };
  return (
    <div>
      <SubHeader
        title={
          params.id ? "Edit a Course Workshop " : "Add a new Course Workshop"
        }
        subtitle="Workshop"
        back="Workshop"
        linkProps={{
          to: "/Workshop",
        }}
      />
      <div style={{ padding: "35px" }}>
        <form onSubmit={formik.handleSubmit}>
          <Box
            style={{
              padding: "20px",
              textAlign: "left",
              boxShadow: "0.5px 0.5px 5px 0.5px rgb(223, 218, 218)",
              borderRadius: "20px",
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} md={5}>
                <Typography variant="h6" sx={{ fontWeight: "400" }}>
                  Workshop Basic Details
                  <Typography sx={{ fontSize: "13px", color: "#a3a3b8" }}>
                    Please provide Workshop details
                  </Typography>
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Grid container>
                  <Grid item xs={12}>
                    <div style={{ margin: 17 }}>
                      <ImageUpload
                        isMultiImages={false}
                        handleImageUpload={handleImageUpload}
                        handleCancelImage={handleCancelImage}
                        uploadedImages={imagesPreview}
                        title="Click to upload Workshop Image"
                      />
                    </div>
                  </Grid>

                  <Grid item xs={12}>
                    <InputLabel>
                      <Typography sx={{ color: "black", fontWeight: "500" }}>
                        Workshop Code
                        <Typography sx={{ color: "red" }} component="span">
                          *
                        </Typography>
                      </Typography>
                    </InputLabel>
                    <CustomTextField
                      name="workshopCode"
                      value={formik.values.workshopCode}
                      onChange={(e) => {
                        const { value } = e.target;
                        if (
                          /^[a-zA-Z0-9]*$/.test(value) &&
                          value.length <= 10
                        ) {
                          formik.handleChange(e);
                        }
                      }}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.workshopCode &&
                        Boolean(formik.errors.workshopCode)
                      }
                    />
                    {formik.touched.workshopCode &&
                      formik.errors.workshopCode && (
                        <Typography
                          sx={{
                            color: "#d32f2f",
                            fontSize: "0.75rem",
                            mt: "-15px",
                          }}
                        >
                          {formik.errors.workshopCode}
                        </Typography>
                      )}
                  </Grid>
                  <br />
                  <Grid item xs={12}>
                    <InputLabel>
                      <Typography sx={{ color: "black", fontWeight: "500" }}>
                        Workshop Name
                        <Typography sx={{ color: "red" }} component="span">
                          *
                        </Typography>
                      </Typography>
                    </InputLabel>
                    <CustomTextField
                      name="workshopName"
                      value={formik.values.workshopName}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.workshopName &&
                        Boolean(formik.errors.workshopName)
                      }
                    />
                    {formik.touched.workshopName &&
                      formik.errors.workshopName && (
                        <Typography
                          sx={{
                            color: "#d32f2f",
                            fontSize: "0.75rem",
                            mt: "-15px",
                          }}
                        >
                          {formik.errors.workshopName}
                        </Typography>
                      )}
                    <Grid item xs={12}>
                      <InputLabel>
                        <Typography sx={{ color: "black", fontWeight: "500" }}>
                          Workshop Description
                          <Typography sx={{ color: "red" }} component="span">
                            *
                          </Typography>
                        </Typography>
                      </InputLabel>
                      <TextField
                        name="workshopDesc"
                        value={formik.values.workshopDesc}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.workshopDesc &&
                          Boolean(formik.errors.workshopDesc)
                        }
                        sx={{ width: "80%" }}
                        id="outlined-multiline-static"
                        multiline
                        rows={4}
                      />
                    </Grid>
                    {formik.touched.workshopDesc &&
                      formik.errors.workshopDesc && (
                        <Typography
                          sx={{
                            color: "#d32f2f",
                            fontSize: "0.75rem",
                          }}
                        >
                          {formik.errors.workshopDesc}
                        </Typography>
                      )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
          <br />
          <br />
          <Box
            style={{
              padding: "20px",
              textAlign: "left",
              boxShadow: "0.5px 0.5px 5px 0.5px rgb(223, 218, 218)",
              borderRadius: "20px",
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} md={5}>
                <Typography variant="h6" sx={{ fontWeight: "400" }}>
                  Workshop Schedule
                  <Typography sx={{ fontSize: "13px", color: "#a3a3b8" }}>
                    Please provide Workshop schedule
                  </Typography>
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Grid container>
                  <Grid item xs={12}>
                    <InputLabel>
                      <Typography sx={{ color: "black", fontWeight: "500" }}>
                        Workshop Date
                        <Typography sx={{ color: "red" }} component="span">
                          *
                        </Typography>
                      </Typography>
                    </InputLabel>
                    <Grid item xs={12}>
                      <LocalizationProvider
                        dateAdapter={AdapterDayjs}
                        size="small"
                      >
                        <DatePicker
                          name="workshopDate"
                          className="customDatePicker"
                          error={
                            formik.touched.workshopDate &&
                            Boolean(formik.errors.workshopDate)
                          }
                          //fullWidth

                          value={selectedworkshopDate}
                          onBlur={formik.handleBlur}
                          onChange={handleworkshopDateChange}
                          minDate={dayjs()}
                          slotProps={{
                            textField: {
                              error:
                                formik.touched.workshopDate &&
                                Boolean(formik.errors.workshopDate),
                              helperText: true,
                            },
                          }}
                          sx={{ "& .MuiInputLabel-root": { mt: "-5px" } }}
                        />
                      </LocalizationProvider>
                      {formik.touched.workshopDate &&
                      formik.errors.workshopDate ? (
                        <Typography
                          sx={{
                            color: "#d32f2f",
                            fontSize: "0.75rem",
                            mt: "-8px",
                            mb: "15px",
                          }}
                        >
                          {formik.errors.workshopDate}
                        </Typography>
                      ) : null}
                    </Grid>
                  </Grid>
                  <br />
                  <Grid item xs={12}>
                    <InputLabel>
                      <Typography sx={{ color: "black", fontWeight: "500" }}>
                        Workshop Day
                        <Typography sx={{ color: "red" }} component="span">
                          *
                        </Typography>
                      </Typography>
                    </InputLabel>
                    <FormControl fullWidth>
                      <Select
                        name="workshopDay"
                        value={formik.values.workshopDay}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.workshopDay &&
                          Boolean(formik.errors.workshopDay)
                        }
                        sx={{ width: "80%", height: 40 }}
                      >
                        {days.map((day, index) => (
                          <MenuItem key={index} value={day}>
                            {day}
                          </MenuItem>
                        ))}
                      </Select>
                      {formik.touched.workshopDay &&
                        formik.errors.workshopDay && (
                          <Typography
                            sx={{
                              color: "#d32f2f",
                              fontSize: "0.75rem",
                              mb: "15px",
                            }}
                          >
                            {formik.errors.workshopDay}
                          </Typography>
                        )}
                    </FormControl>
                    <br />
                    <Grid item xs={12}>
                      <InputLabel>
                        <Typography
                          sx={{ color: "black", fontWeight: "500", pt: "10px" }}
                        >
                          Workshop Time
                          <Typography sx={{ color: "red" }} component="span">
                            *
                          </Typography>
                        </Typography>
                      </InputLabel>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={["TimePicker"]}>
                          <TimePicker
                            className="customDatePicker"
                            name="workshopTime"
                            error={
                              formik.touched.workshopTime &&
                              Boolean(formik.errors.workshopTime)
                            }
                            value={selectedWorkshopTime}
                            onChange={handleWorkshopTimeChange}
                            onBlur={formik.handleBlur}
                            minDate={dayjs()}
                            slotProps={{
                              textField: {
                                error:
                                  formik.touched.workshopTime &&
                                  Boolean(formik.errors.workshopTime),
                                helperText: true,
                              },
                            }}
                            sx={{ "& .MuiInputLabel-root": { mt: "-0px" } }}
                          />
                        </DemoContainer>
                      </LocalizationProvider>
                      {formik.touched.workshopTime &&
                      formik.errors.workshopTime ? (
                        <Typography
                          sx={{
                            color: "#d32f2f",
                            fontSize: "0.75rem",
                            mt: "-8px",
                            mb: "15px",
                          }}
                        >
                          {formik.errors.workshopTime}
                        </Typography>
                      ) : null}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
          <br />
          <br />
          <Box
            style={{
              padding: "20px",
              textAlign: "left",
              boxShadow: "0.5px 0.5px 5px 0.5px rgb(223, 218, 218)",
              borderRadius: "20px",
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} md={5}>
                <Typography variant="h6" sx={{ fontWeight: "400" }}>
                  Course label and Fees Details
                  <Typography sx={{ fontSize: "13px", color: "#a3a3b8" }}>
                    Please provide your course label and Fees
                  </Typography>
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Grid container>
                  <Grid item xs={12}>
                    <Grid container>
                      <Grid item xs={12}>
                        <Typography sx={{ color: "black", fontWeight: "500" }}>
                          Label
                          <Typography sx={{ color: "red" }} component="span">
                            *
                          </Typography>
                        </Typography>
                        <FormControl fullWidth>
                          <Select
                            name="label"
                            value={formik.values.label}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={
                              formik.touched.label &&
                              Boolean(formik.errors.label)
                            }
                            sx={{ width: "80%", height: 40 }}
                          >
                            <MenuItem value="Beginner">beginner</MenuItem>
                            <MenuItem value="Elementary">elementary</MenuItem>
                            <MenuItem value="Intermediate">
                              intermediate
                            </MenuItem>
                            <MenuItem value="Upperintermediate">
                              upper Intermediate
                            </MenuItem>
                            <MenuItem value="Advanced">advanced</MenuItem>
                            <MenuItem value="Proficient">proficient</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      <br />
                      {formik.touched.label && formik.errors.label && (
                        <Typography
                          sx={{
                            color: "#d32f2f",
                            fontSize: "0.75rem",
                          }}
                        >
                          {formik.errors.label}
                        </Typography>
                      )}
                    </Grid>
                    <br />
                    <Grid item xs={12}>
                      <InputLabel>
                        <Typography sx={{ color: "black", fontWeight: "500" }}>
                          Trainer Name
                          <Typography sx={{ color: "red" }} component="span">
                            *
                          </Typography>
                        </Typography>
                      </InputLabel>
                      <CustomTextField
                        name="trainer"
                        value={formik.values.trainer}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.trainer &&
                          Boolean(formik.errors.trainer)
                        }
                      />
                    </Grid>
                    {formik.touched.trainer && formik.errors.trainer && (
                      <Typography
                        sx={{
                          color: "#d32f2f",
                          fontSize: "0.75rem",
                          mt: "-15px",
                        }}
                      >
                        {formik.errors.trainer}
                      </Typography>
                    )}
                    <Grid item xs={12}>
                      <InputLabel>
                        <Typography sx={{ color: "black", fontWeight: "500" }}>
                          Skills
                          <Typography sx={{ color: "red" }} component="span">
                            *
                          </Typography>
                        </Typography>
                      </InputLabel>
                      <CustomTextField
                        name="skills"
                        value={formik.values.skills}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.skills && Boolean(formik.errors.skills)
                        }
                      />
                    </Grid>
                    {formik.touched.skills && formik.errors.skills && (
                      <Typography
                        sx={{
                          color: "#d32f2f",
                          fontSize: "0.75rem",
                          mt: "-15px",
                        }}
                      >
                        {formik.errors.skills}
                      </Typography>
                    )}
                    <Grid item xs={12}>
                      <InputLabel>
                        <Typography sx={{ color: "black", fontWeight: "500" }}>
                          Real Fees
                          <Typography sx={{ color: "red" }} component="span">
                            *
                          </Typography>
                        </Typography>
                      </InputLabel>
                      <CustomTextField
                        name="realfees"
                        value={formik.values.realfees}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.realfees &&
                          Boolean(formik.errors.realfees)
                        }
                        onInput={(e) => {
                          e.target.value = e.target.value.replace(
                            /[^0-9]/g,
                            ""
                          );
                        }}
                      />
                    </Grid>
                    {formik.touched.realfees && formik.errors.realfees && (
                      <Typography
                        sx={{
                          color: "#d32f2f",
                          fontSize: "0.75rem",
                          mt: "-15px",
                        }}
                      >
                        {formik.errors.realfees}
                      </Typography>
                    )}
                    <Grid item xs={12}>
                      <InputLabel>
                        <Typography sx={{ color: "black", fontWeight: "500" }}>
                          Discount Fees
                          <Typography sx={{ color: "red" }} component="span">
                            *
                          </Typography>
                        </Typography>
                      </InputLabel>
                      <CustomTextField
                        name="fees"
                        value={formik.values.fees}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.fees && Boolean(formik.errors.fees)
                        }
                        onInput={(e) => {
                          e.target.value = e.target.value.replace(
                            /[^0-9]/g,
                            ""
                          );
                        }}
                      />
                    </Grid>
                    {formik.touched.fees && formik.errors.fees && (
                      <Typography
                        sx={{
                          color: "#d32f2f",
                          fontSize: "0.75rem",
                          mt: "-15px",
                        }}
                      >
                        {formik.errors.fees}
                      </Typography>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
          <br />
          <br />
          <Box
            style={{
              padding: "20px",
              textAlign: "left",
              boxShadow: "0.5px 0.5px 5px 0.5px rgb(223, 218, 218)",
              borderRadius: "20px",
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} md={5}>
                <Typography variant="h6" sx={{ fontWeight: "400" }}>
                  Workshop Status
                  <Typography sx={{ fontSize: "13px", color: "#a3a3b8" }}>
                    Please provide your Workshop status
                  </Typography>
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Grid container>
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <Stack direction="row">
                        <Typography>Active</Typography>
                        <Switch
                          name="isActive"
                          color="primary"
                          checked={formik.values.isActive}
                          onChange={formik.handleChange}
                        />
                      </Stack>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
          <Box pt={3} display="flex" flexDirection="row-reverse">
            <Stack direction="row" spacing={4}>
              <Link to={AppRouts.WORKSHOP}>
                <Button type="cancle" variant="contained" color="primary">
                  Cancel
                </Button>
              </Link>
              <Button type="submit" variant="contained" color="primary">
                Submit
              </Button>
            </Stack>
          </Box>
        </form>
      </div>
    </div>
  );
};

export default AddWorkshop;
