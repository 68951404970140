import React, { useState, useEffect } from "react";
import { Grid, Typography, Fab, IconButton } from "@mui/material";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import CancelIcon from "@mui/icons-material/Cancel";

const VideoUpload = ({
  handleVideoUpload,
  uploadedVideos,
  handleCancelVideo,
  title,
  inputRef,
}) => {
  const [videosPreview, setVideosPreview] = useState([]);
  const [showUploadIcon, setShowUploadIcon] = useState(true);

  useEffect(() => {
    if (uploadedVideos?.length > 0) {
      setVideosPreview(uploadedVideos);
      setShowUploadIcon(false);
    } else {
      setVideosPreview([]);
      setShowUploadIcon(true);
    }
  }, [uploadedVideos]);

  const handleRemoveVideo = (index) => {
    const updatedVideos = videosPreview.filter((_, i) => i !== index);
    setVideosPreview(updatedVideos);
    handleCancelVideo(index);

    if (updatedVideos.length === 0) {
      setShowUploadIcon(true);
    }
  };

  return (
    <Grid xs={12} md={8} item>
      <label htmlFor="video-upload" className="upload-label">
        <input
          ref={inputRef}
          type="file"
          id="video-upload"
          accept="video/*"
          style={{ display: "none" }}
          onChange={handleVideoUpload}
        />
        {showUploadIcon && (
          <div style={{ marginLeft: "130px" }}>
            <Fab component="span">
              <FileUploadIcon sx={{ width: 40, height: 67 }} />
            </Fab>
            <Typography className="click" sx={{ marginLeft: "-80px" }}>
              {title}
            </Typography>
          </div>
        )}
      </label>
      <br />
      <div className="videos">
        {videosPreview?.map((video, index) => (
          <div key={index} className="video-card">
            <video
              src={video.videoUrl}
              controls
              className="uploaded-video"
              style={{
                width: 200,
                height: 200,
                borderRadius: 22,
                marginTop: "5px",
                marginLeft: "80px",
              }}
            />
            <IconButton onClick={() => handleRemoveVideo(index)}>
              <CancelIcon />
            </IconButton>
          </div>
        ))}
      </div>
    </Grid>
  );
};

export default VideoUpload;
