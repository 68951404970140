import {
  Avatar,
  Card,
  Container,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Header from "./Header";
import { deepOrange } from "@mui/material/colors";
import EditNoteOutlinedIcon from "@mui/icons-material/EditNoteOutlined";
import { AppRouts, TableName } from "./Constant";
import useSupabase from "./utils/useSupabase";
import { Link } from "react-router-dom";

const Testimonials = () => {
  const [testimonials, settestimonials] = useState([]);
  const supabase = useSupabase();

  useEffect(() => {
    const fetchtestimonials = async () => {
      const { data, error } = await supabase
        .from(TableName.TESTIMONIALS)
        .select("*");

      if (error) {
        console.error("Error fetching data:", error.message);
      } else {
        settestimonials(data);
      }
    };

    fetchtestimonials();
  }, [supabase]);

  return (
    <>
      <div style={{ padding: "20px" }}>
        <Container className="ProductContainer">
          <Header
            title="Testimonials"
            subtitle="Testimonials"
            subtitle1="List"
            showAddButton={true}
            linkProps={{
              to: AppRouts.ADDTESTIMONIALS_WITHOUT_ID,
            }}
          />
          {testimonials.map((testimonial) => (
            <Card
              key={testimonial.id}
              style={{
                width: "90%",
                marginTop: "25px",
                borderRadius: "15px",
                paddingTop: "25px",
                paddingLeft: "55px",
                marginLeft: "15px",
              }}
            >
              <Stack direction="row" spacing={2}>
                <Avatar
                  sx={{ bgcolor: deepOrange[500], width: 56, height: 56 }}
                >
                  {testimonial.firstLetter ||
                    testimonial.name.charAt(0).toUpperCase()}
                </Avatar>

                <Typography
                  variant="h5"
                  sx={{ font: "Inter", fontWeight: "700" }}
                >
                  {testimonial.name}
                </Typography>
              </Stack>
              <Typography sx={{ font: "Inter", paddingLeft: "75px" }}>
                {testimonial.review}
              </Typography>
              <Link to={`${AppRouts.TESTIMONIALS}/${testimonial.id}`}>
                <IconButton sx={{ marginLeft: "auto", display: "flex" }}>
                  <EditNoteOutlinedIcon sx={{ fontSize: "40px" }} />
                </IconButton>
              </Link>
            </Card>
          ))}
        </Container>
      </div>
    </>
  );
};

export default Testimonials;
