import React, { useState, useEffect } from "react";
import {
  Container,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  InputAdornment,
  TableContainer,
  TablePagination,
  Box,
  Divider,
} from "@mui/material";
import { Search } from "@mui/icons-material";
import useSupabase from "./utils/useSupabase";
import Header from "./Header";
import { TableName } from "./Constant";

const Payment = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(4);
  const [payments, setPayments] = useState([]);
  const supabase = useSupabase();

  useEffect(
    () => {
      fetchPaymentData(0, searchTerm);
    },
    //eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  async function fetchPaymentData(newPage, searchKey) {
    try {
      let query = supabase
        .from(TableName.PAYMENT)
        .select("*", { count: "exact" });
      if (searchKey.trim() !== "") {
        query = query.ilike("studentName", `%${searchKey}%`);
      }
      query = query.order("studentName");

      const { data, count, error } = await query
        .range(newPage * rowsPerPage, (newPage + 1) * rowsPerPage - 1)
        .limit(rowsPerPage);

      if (error) {
        console.error("Error fetching payments:", error);
      } else {
        console.log("Fetched data:", data);
        setPayments(data || []);
        setTotalItems(count || 0);
      }
    } catch (error) {
      console.error("Error performing search:", error);
    }
  }

  const handleSearch = async (event) => {
    setSearchTerm(event.target.value);
    setTimeout(() => {
      fetchPaymentData(0, event.target.value);
    }, 500);
  };

  const handleChangePage = (event, newPage) => {
    fetchPaymentData(newPage, searchTerm);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    fetchPaymentData(0, searchTerm);
  };

  return (
    <div style={{ padding: "20px" }}>
      <Container className="ProductContainer">
        <Header
          title="Payment Details"
          subtitle="Payment"
          subtitle1="List"
          showAddButton={false}
        />
        <div className="topContent">
          <Grid item lg={12} xs={12} sm={6} md={6}>
            <TextField
              sx={{ p: "7px" }}
              id="standard-search"
              type="search"
              variant="standard"
              fullWidth
              placeholder="Search by Student name"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
                disableUnderline: true,
              }}
              onChange={handleSearch}
            />
          </Grid>
          <Grid item xs={12} lg={12} sm={12} md={12}>
            <Divider className="divider" />
          </Grid>
        </div>

        <Grid item xs={12} lg={12} sm={12} md={12}>
          <TableContainer
            style={{ width: "100.5%" }}
            sx={{ boxShadow: "0px 0.5px 1px 0.5px rgb(223, 218, 218)" }}
          >
            <Table sx={{ minWidth: 650, borderBottom: "none" }}>
              <TableHead>
                <TableRow sx={{ backgroundColor: "#F5F5F5" }}>
                  <TableCell
                    align="center"
                    className="cell"
                    sx={{ color: "rgb(47, 55, 70)", fontWeight: "bold" }}
                  >
                    Invoice Id
                  </TableCell>
                  <TableCell
                    align="center"
                    className="cell"
                    sx={{ color: "rgb(47, 55, 70)", fontWeight: "bold" }}
                  >
                    Payment Id
                  </TableCell>

                  <TableCell
                    align="center"
                    className="cell"
                    sx={{ color: "rgb(47, 55, 70)", fontWeight: "bold" }}
                  >
                    Course Id
                  </TableCell>
                  <TableCell
                    align="center"
                    className="cell"
                    sx={{ color: "rgb(47, 55, 70)", fontWeight: "bold" }}
                  >
                    Total Amount
                  </TableCell>
                  <TableCell
                    align="center"
                    className="cell"
                    sx={{ color: "rgb(47, 55, 70)", fontWeight: "bold" }}
                  >
                    Student Name
                  </TableCell>
                  <TableCell
                    align="center"
                    className="cell"
                    sx={{ color: "rgb(47, 55, 70)", fontWeight: "bold" }}
                  >
                    Student Contact NO.
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {payments.map((payment, id) => (
                  <TableRow key={id}>
                    <TableCell sx={{ textAlign: "center", lineHeight: "1" }}>
                      {payment.invoiceId}
                    </TableCell>
                    <TableCell sx={{ textAlign: "center", lineHeight: "1" }}>
                      {payment.paymentId}
                    </TableCell>
                    <TableCell sx={{ textAlign: "center", lineHeight: "1" }}>
                      {payment.courseId}
                    </TableCell>
                    <TableCell sx={{ textAlign: "center", lineHeight: "1" }}>
                      {payment.totalAmount}
                    </TableCell>
                    <TableCell sx={{ textAlign: "center", lineHeight: "1" }}>
                      {payment.studentName}
                    </TableCell>
                    <TableCell sx={{ textAlign: "center", lineHeight: "1" }}>
                      {payment.contactNo}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Box className="page">
            <TablePagination
              component="div"
              count={totalItems}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              rowsPerPageOptions={[]}
              sx={{
                "& .MuiPaginationItem-root": {
                  fontSize: "12px",
                },
                "& .Mui-selected": {
                  backgroundColor: "#1e88e5",
                  color: "rgb(108, 115, 127)",
                  "&:hover": {
                    backgroundColor: "#1565c0",
                  },
                },
                "& .MuiTablePagination-displayedRows": {
                  fontSize: "13px",
                  color: "rgb(108, 115, 127)",
                },
              }}
            />
          </Box>
        </Grid>
      </Container>
    </div>
  );
};

export default Payment;
