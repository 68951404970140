import { Grid, IconButton, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import ArrowBackSharpIcon from "@mui/icons-material/ArrowBackSharp";
import { AppRouts } from "./Constant";

const SubHeader = (props) => {
  const navigate = useNavigate();
  const { linkProps } = props;

  const handleBack = () => {
    if (linkProps && linkProps.to) {
      navigate(linkProps.to); // Navigate to the provided route
    }
  };
  return (
    <div style={{ paddingLeft: "65px", paddingTop: "50px" }}>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="flex-start"
      >
        <Grid item>
          <Link
            {...linkProps}
            style={{
              textDecoration: "none",
              display: "flex",
              alignItems: "center",
            }}
            onClick={handleBack} // Call handleBack function on click
          >
            <IconButton>
              <ArrowBackSharpIcon />
            </IconButton>
            <Typography
              variant="h6"
              component="span"
              color="textPrimary"
              fontWeight="inherit"
            >
              {props.back}
            </Typography>
          </Link>
        </Grid>
        <Grid item>
          <Typography variant="h4" fontWeight="bold">
            {props.title}
          </Typography>
        </Grid>
        <Grid item container alignItems="center">
          <Link
            to={AppRouts.DASHBOARD}
            underline="hover"
            style={{
              fontSize: "12px",
              color: "rgb(17, 25, 39)",
              fontWeight: "600",
              cursor: "pointer",
            }}
          >
            Dashboard
          </Link>
          <Typography
            variant="body2"
            color="textSecondary"
            style={{
              fontSize: "20px",
              paddingLeft: "2px",
              paddingRight: "2px",
            }}
          >
            &bull; {/* Larger dot character */}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            {props.subtitle}
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
};

export default SubHeader;
