import { Button, Grid, Typography } from "@mui/material";
import React from "react";
import AddIcon from "@mui/icons-material/Add";
import { Link } from "react-router-dom";

const Header = (props) => {
  const { linkProps, showAddButton } = props;
  return (
    <div style={{ paddingLeft: "25px", paddingTop: "60px" }}>
      <Grid container maxWidth="lg">
        <Grid item lg={11.2} xs={12} sm={6} md={6}>
          <Typography variant="h4" sx={{ fontWeight: "bold" }}>
            {props.title}
          </Typography>
        </Grid>
        {showAddButton && ( // Conditionally render the add button
          <Grid item lg={0.8} xs={12} sm={6} md={6} textAlign="right">
            <Link {...linkProps} style={{ textDecoration: "none" }}>
              <Button
                variant="contained"
                color="primary"
                startIcon={<AddIcon />}
              >
                Add
              </Button>
            </Link>
          </Grid>
        )}
      </Grid>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: "8px",
          marginBottom: "3rem",
        }}
      >
        <Link
          to="/"
          underline="hover"
          style={{
            fontSize: "12px",
            color: "rgb(17, 25, 39)",
            fontWeight: "600",
          }}
        >
          Dashboard
        </Link>
        <div
          style={{
            backgroundColor: "rgb(108, 115, 127)",
            borderRadius: "50%",
            height: "4px",
            width: "4px",
            padding: "0%",
            margin: "0",
          }}
        ></div>
        <Typography
          sx={{
            fontSize: "12px",
            color: "rgb(108, 115, 127)",
            paddingTop: "3px",
            marginLeft: "-2px",
          }}
        >
          {props.subtitle}
        </Typography>
        <div
          style={{
            backgroundColor: "rgb(108, 115, 127)",
            borderRadius: "50%",
            height: "4px",
            width: "4px",
            padding: "0%",
            margin: "0",
          }}
        ></div>
        <Typography
          sx={{
            fontSize: "12px",
            color: "rgb(108, 115, 127)",
            paddingTop: "3px",
            marginLeft: "-2px",
          }}
        >
          {props.subtitle1}
        </Typography>
      </div>
    </div>
  );
};

export default Header;
