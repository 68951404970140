import { Container, Button } from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import Header from "./Header";
import { AppRouts, TableName } from "./Constant";
import { Link, useParams } from "react-router-dom";
import { Fullscreen } from "@mui/icons-material";
import useSupabase from "./utils/useSupabase";

const CourseDetails = () => {
  const supabase = useSupabase();
  const { id } = useParams();
  const [courseData, setCourseData] = useState([]);
  const [showAddButton, setShowAddButton] = useState(true);

  useEffect(() => {
    fetchCourseDetails();
  }, []);

  const fetchCourseDetails = async () => {
    const { data } = await supabase
      .from(TableName.COURSEDETAILS)
      .select()
      .eq("courseId", id);
    setCourseData(data);
    setShowAddButton(data?.length === 0); // Show add button if no data is present
  };

  return (
    <Fragment>
      <div style={{ padding: "20px" }}>
        <Container className="ProductContainer">
          <Header
            title="Course Details"
            subtitle="Course Details"
            subtitle1="List"
            showAddButton={showAddButton} // Use the state variable here
            linkProps={{
              to: `${AppRouts.ADDCOURSEDETAILS_WITHOUT_ID}/${id}`,
            }}
          />
          {courseData?.map((course) => (
            <div>
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <Link
                  to={`${AppRouts.ADDCOURSEDETAILS}/${id}/${course.detailId}`}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    style={{ marginBottom: "10px" }}
                  >
                    Edit
                  </Button>
                </Link>
              </div>
              <div
                style={{
                  width: Fullscreen,
                  height: "40vh",
                }}
              >
                <img
                  src={course.imageUrl}
                  alt="coursedetails"
                  style={{
                    width: "100%",
                    backgroundSize: "cover",
                    height: "100%",
                  }}
                />
              </div>
              <br />
              <h1>{course.courseName}</h1>
              <h5 style={{ marginTop: "3px" }}> By EDU Tech</h5>
              <br />
              <p>{course.courseDesc}</p>
            </div>
          ))}
        </Container>
      </div>
    </Fragment>
  );
};

export default CourseDetails;
