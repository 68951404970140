import { toast } from "react-toastify";

const ToastStatus = {
  position: "top-right",
  autoClose: 1000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "colored",
};

export const showToast = (type, message) => {
  console.log("gggg");
  switch (type) {
    case "success":
      toast.success(`${message} Added Successfully.`, ToastStatus);
      break;
    case "cancel":
      toast.error(`${message} Canceled Successfully.`, ToastStatus);
      break;
    case "delete":
      toast.success(`${message} Deleted Successfully.`, ToastStatus);
      break;
    case "update":
      toast.success(`${message} Updated Successfully.`, ToastStatus);
      break;
    case "uploaded":
      toast.success(`${message} Uploaded Successfully.`, ToastStatus);
      break;
    case "signIn":
      toast.error("Invalid Username or Password", {
        autoClose: 1000,
        theme: "colored",
      });
      break;
    default:
  }
};
