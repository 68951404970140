import { Typography, Box, IconButton } from "@mui/material";

import { Cancel } from "@mui/icons-material";

function SelectedFilter({ selectedOptions, onFilterChange }) {
  const handleChange = (index) => {
    onFilterChange([...selectedOptions?.filter((_, idx) => idx !== index)]);
  };

  return (
    <div className="filterBox">
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          marginTop: "10px",
        }}
      >
        {selectedOptions?.length > 0 ? (
          selectedOptions?.map((category, index) => (
            <Box
              key={index}
              sx={{
                display: "flex",
                alignItems: "center",
                borderRadius: "50px",
                backgroundColor: "white",
                padding: "2px 5px",
                marginRight: "10px",
                marginBottom: "15px",
                border: "1px solid gray",
              }}
            >
              <Typography
                sx={{
                  color: "rgb(47, 55, 70)",
                  fontWeight: "600",
                  fontSize: "12px",
                }}
              >
                {`${category.title}:`}
              </Typography>

              <Typography
                sx={{ marginLeft: "5px", fontSize: "12px" }}
                variant="body2"
              >
                {category.value}
              </Typography>
              <IconButton
                onClick={() => handleChange(index)}
                size="small"
                sx={{ color: "gray" }}
              >
                <Cancel />
              </IconButton>
            </Box>
          ))
        ) : (
          <Typography
            sx={{
              fontWeight: "bold",
              color: "rgb(133 136 141)",
              fontSize: "13px",
              letterSpacing: "0.5px",
              marginBottom: "15px",
              marginLeft: "13px",
            }}
          >
            {`No filters applied`}
          </Typography>
        )}
      </Box>
    </div>
  );
}

export default SelectedFilter;
