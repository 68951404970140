import React, { useState, useEffect } from "react";
import SubHeader from "./SubHeader";
import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  Stack,
  Switch,
  Typography,
} from "@mui/material";
import CustomTextField from "./CustomTextField";
import { Link } from "react-router-dom";
import { AppRouts, TableName } from "./Constant";
import { useFormik } from "formik";
import { useNavigate, useParams } from "react-router";
import * as yup from "yup";
import useSupabase from "./utils/useSupabase";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import ImageUpload from "./ImageUpload";
import { removeImage, uploadImage } from "./Helperfunction";
import { StorageBucket } from "./Constant";
import { showToast } from "./toastUtils";

const AddCourse = () => {
  const supabase = useSupabase();
  const navigate = useNavigate();
  const params = useParams();
  const [imagesPreview, setImagesPreview] = useState([]);
  const [originalImage, setOriginalImage] = useState();
  const [isNewImageUp, setIsNewImageUp] = useState(false);
  const [category, setCategory] = useState([]);
  const [getSubCategory, setGetSubCategory] = useState([]);
  const initialValues = {
    courseCode: "",
    courseName: "",
    courseCategory: "",
    courseSubCategory: "",
    realfees: "",
    fees: "",
    duration: "",
    lable: "",
    trainer: "",
    skills: "",
    isActive: false,
  };

  const validationSchema = yup.object({
    courseCode: yup.string().required("Course Code is required"),
    courseName: yup.string().required("Course Name is required"),
    courseCategory: yup.string().required("Course Category is required"),
    courseSubCategory: yup.string().required("Course SubCategory is required"),
    realfees: yup.string().required("Fees is required"),
    fees: yup.string().required("Fees is required"),
    duration: yup.string().required("Duration is required"),
    lable: yup.string().required("Lable is required"),
    trainer: yup.string().required("Trainer Name is required"),
    skills: yup.string().required("Skills is required"),
  });

  useEffect(
    () => {
      const fetchCourseData = async () => {
        const courseId = params.id;
        const { data, error } = await supabase
          .from(TableName.COURSE)
          .select()
          .eq("id", courseId);

        if (error) {
          console.error("Error fetching course data:", error.message);
        } else if (data && data.length > 0) {
          const course = data[0];
          formik.setValues({
            courseCode: course.courseCode,
            courseName: course.courseName,
            courseCategory: course.courseCategory,
            courseSubCategory: course.courseSubCategory,
            realfees: course.realfees,
            fees: course.fees,
            duration: course.duration,
            lable: course.lable,
            trainer: course.trainer,
            skills: course.skills,
            isActive: course.isActive,
          });
          if (course.imageUrl) {
            setImagesPreview([{ imageUrl: course.imageUrl }]);
          }
          setOriginalImage(course.imageUrl);
        }
      };

      if (params.id) {
        fetchCourseData();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [params.id, supabase]
  );
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: async (values, event) => {
      if (params.id) {
        let publicUrl = originalImage;
        if (isNewImageUp) {
          await removeImage(StorageBucket.COURSE, originalImage);
          publicUrl = await uploadImage(
            StorageBucket.COURSE,
            imagesPreview[0].imageFile
          );
        }
        const { data, error } = await supabase
          .from(TableName.COURSE)
          .update([
            {
              courseCode: values.code,
              courseName: values.name,
              courseCategory: values.courseCategory,
              courseSubCategory: values.courseSubCategory,
              realfees: values.realfees,
              fees: values.fees,
              duration: values.duration,
              lable: values.lable,
              trainer: values.trainer,
              skills: values.skills,
              isActive: values.isActive,
              imageUrl: publicUrl,
            },
          ])
          .eq("id", params.id);
        if (!error) {
          console.log("course data update", data);
          navigate(AppRouts.COURSE);
          showToast("update", "Course");
        }
      } else {
        try {
          let publicUrl = "";
          if (imagesPreview.length > 0) {
            publicUrl = await uploadImage(
              StorageBucket.COURSE,
              imagesPreview[0].imageFile
            );
          }
          const { error: insertError } = await supabase
            .from(TableName.COURSE)
            .insert([
              {
                courseCode: values.courseCode,
                courseName: values.courseName,
                isActive: values.isActive,
                courseCategory: values.courseCategory,
                courseSubCategory: values.courseSubCategory,
                realfees: values.realfees,
                fees: values.fees,
                duration: values.duration,
                lable: values.lable,
                trainer: values.trainer,
                skills: values.skills,
                imageUrl: publicUrl,
              },
            ]);

          if (insertError) {
            console.error("Error inserting data:", insertError.message);
            return;
          }

          navigate(AppRouts.COURSE);
          showToast("success", "Course");
        } catch (error) {
          console.error("Error:", error.message);
        }
      }
    },
  });
  const handleImageUpload = async (event) => {
    if (event?.target?.files?.length > 0) {
      const imageUrl = URL.createObjectURL(event?.target?.files[0]);
      setImagesPreview([
        {
          imageFile: event?.target?.files[0],
          imageUrl: imageUrl,
        },
      ]);
      setIsNewImageUp(true);
      setOriginalImage(imageUrl);
    }
  };

  const handleCancelImage = (index) => {
    setImagesPreview([]);
    setOriginalImage(null);
  };
  useEffect(
    () => {
      getcategory();
      getSubCategoryData();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  const getcategory = async () => {
    const { data: categoryData, error: categoryError } = await supabase
      .from(TableName.CATEGORY)
      .select()
      .eq("isActive", true);
    if (categoryError) {
      console.error("Error fetching categories:", categoryError);
      return;
    }

    setCategory(categoryData);
  };
  const getSubCategoryData = async () => {
    const { data } = await supabase
      .from(TableName.SUBCATEGORY)
      .select()
      .eq("isActive", true);
    setGetSubCategory(data);
  };
  return (
    <div>
      <SubHeader
        title={params.id ? "Edit a Course" : "Add a new Course"}
        subtitle="Course"
        back="Course"
        linkProps={{
          to: "/Course",
        }}
      />
      <div style={{ padding: "35px" }}>
        <form onSubmit={formik.handleSubmit}>
          <Box
            style={{
              padding: "20px",
              textAlign: "left",
              boxShadow: "0.5px 0.5px 5px 0.5px rgb(223, 218, 218)",
              borderRadius: "20px",
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} md={5}>
                <Typography variant="h6" sx={{ fontWeight: "400" }}>
                  Course Basic Details
                  <Typography sx={{ fontSize: "13px", color: "#a3a3b8" }}>
                    Please provide Course details
                  </Typography>
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Grid container>
                  <Grid item xs={12}>
                    <div style={{ margin: 17 }}>
                      <ImageUpload
                        isMultiImages={false}
                        handleImageUpload={handleImageUpload}
                        handleCancelImage={handleCancelImage}
                        uploadedImages={imagesPreview}
                        title="Click to upload course Image"
                      />
                    </div>
                  </Grid>

                  <Grid item xs={12}>
                    <InputLabel>
                      <Typography sx={{ color: "black", fontWeight: "500" }}>
                        Course Code
                        <Typography sx={{ color: "red" }} component="span">
                          *
                        </Typography>
                      </Typography>
                    </InputLabel>
                    <CustomTextField
                      name="courseCode"
                      value={formik.values.courseCode}
                      onChange={(e) => {
                        const { value } = e.target;
                        if (
                          /^[a-zA-Z0-9]*$/.test(value) &&
                          value.length <= 10
                        ) {
                          formik.handleChange(e);
                        }
                      }}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.courseCode &&
                        Boolean(formik.errors.courseCode)
                      }
                    />
                    {formik.touched.courseCode && formik.errors.courseCode && (
                      <Typography
                        sx={{
                          color: "#d32f2f",
                          fontSize: "0.75rem",
                          mt: "-15px",
                        }}
                      >
                        {formik.errors.courseCode}
                      </Typography>
                    )}
                  </Grid>
                  <br />
                  <Grid item xs={12}>
                    <InputLabel>
                      <Typography sx={{ color: "black", fontWeight: "500" }}>
                        Course Name
                        <Typography sx={{ color: "red" }} component="span">
                          *
                        </Typography>
                      </Typography>
                    </InputLabel>
                    <CustomTextField
                      name="courseName"
                      value={formik.values.courseName}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.courseName &&
                        Boolean(formik.errors.courseName)
                      }
                    />
                    {formik.touched.courseName && formik.errors.courseName && (
                      <Typography
                        sx={{
                          color: "#d32f2f",
                          fontSize: "0.75rem",
                          mt: "-15px",
                        }}
                      >
                        {formik.errors.courseName}
                      </Typography>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
          <br />
          <br />
          <Box
            style={{
              padding: "20px",
              textAlign: "left",
              boxShadow: "0.5px 0.5px 5px 0.5px rgb(223, 218, 218)",
              borderRadius: "20px",
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} md={5}>
                <Typography variant="h6" sx={{ fontWeight: "400" }}>
                  Course Fees Details
                  <Typography sx={{ fontSize: "13px", color: "#a3a3b8" }}>
                    Please provide your course fees and duration
                  </Typography>
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Grid container>
                  <Grid item xs={12}>
                    <Grid item xs={12}>
                      <InputLabel>
                        <Typography sx={{ color: "black", fontWeight: "500" }}>
                          Real Fees
                          <Typography sx={{ color: "red" }} component="span">
                            *
                          </Typography>
                        </Typography>
                      </InputLabel>
                      <CustomTextField
                        name="realfees"
                        value={formik.values.realfees}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.realfees &&
                          Boolean(formik.errors.realfees)
                        }
                        onInput={(e) => {
                          e.target.value = e.target.value.replace(
                            /[^0-9]/g,
                            ""
                          );
                        }}
                      />
                    </Grid>
                    {formik.touched.realfees && formik.errors.realfees && (
                      <Typography
                        sx={{
                          color: "#d32f2f",
                          fontSize: "0.75rem",
                          mt: "-15px",
                        }}
                      >
                        {formik.errors.realfees}
                      </Typography>
                    )}
                    <Grid item xs={12}>
                      <InputLabel>
                        <Typography sx={{ color: "black", fontWeight: "500" }}>
                          Discount Fees
                          <Typography sx={{ color: "red" }} component="span">
                            *
                          </Typography>
                        </Typography>
                      </InputLabel>
                      <CustomTextField
                        name="fees"
                        value={formik.values.fees}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.fees && Boolean(formik.errors.fees)
                        }
                        onInput={(e) => {
                          e.target.value = e.target.value.replace(
                            /[^0-9]/g,
                            ""
                          );
                        }}
                      />
                    </Grid>
                    {formik.touched.fees && formik.errors.fees && (
                      <Typography
                        sx={{
                          color: "#d32f2f",
                          fontSize: "0.75rem",
                          mt: "-15px",
                        }}
                      >
                        {formik.errors.fees}
                      </Typography>
                    )}
                    <Grid item xs={12}>
                      <InputLabel>
                        <Typography sx={{ color: "black", fontWeight: "500" }}>
                          Duration
                          <Typography sx={{ color: "red" }} component="span">
                            *
                          </Typography>
                        </Typography>
                      </InputLabel>
                      <CustomTextField
                        name="duration"
                        value={formik.values.duration}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.duration &&
                          Boolean(formik.errors.duration)
                        }
                      />
                    </Grid>
                    {formik.touched.duration && formik.errors.duration && (
                      <Typography
                        sx={{
                          color: "#d32f2f",
                          fontSize: "0.75rem",
                          mt: "-15px",
                        }}
                      >
                        {formik.errors.duration}
                      </Typography>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
          <br />
          <br />
          <Box
            style={{
              padding: "20px",
              textAlign: "left",
              boxShadow: "0.5px 0.5px 5px 0.5px rgb(223, 218, 218)",
              borderRadius: "20px",
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} md={5}>
                <Typography variant="h6" sx={{ fontWeight: "400" }}>
                  Course Lable and Skills Details
                  <Typography sx={{ fontSize: "13px", color: "#a3a3b8" }}>
                    Please provide your course Lable and Skills
                  </Typography>
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Grid container>
                  <Grid item xs={12}>
                    <Grid container>
                      <Grid item xs={12}>
                        <Typography sx={{ color: "black", fontWeight: "500" }}>
                          Lable
                          <Typography sx={{ color: "red" }} component="span">
                            *
                          </Typography>
                        </Typography>
                        <FormControl fullWidth>
                          <Select
                            name="lable"
                            value={formik.values.lable}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={
                              formik.touched.lable &&
                              Boolean(formik.errors.lable)
                            }
                            sx={{ width: "80%", height: 40 }}
                          >
                            <MenuItem value="Beginner">beginner</MenuItem>
                            <MenuItem value="Elementary">elementary</MenuItem>
                            <MenuItem value="Intermediate">
                              intermediate
                            </MenuItem>
                            <MenuItem value="Upperintermediate">
                              upper Intermediate
                            </MenuItem>
                            <MenuItem value="Advanced">advanced</MenuItem>
                            <MenuItem value="Proficient">proficient</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      <br />
                      {formik.touched.lable && formik.errors.lable && (
                        <Typography
                          sx={{
                            color: "#d32f2f",
                            fontSize: "0.75rem",
                          }}
                        >
                          {formik.errors.lable}
                        </Typography>
                      )}
                    </Grid>
                    <br />
                    <Grid item xs={12}>
                      <InputLabel>
                        <Typography sx={{ color: "black", fontWeight: "500" }}>
                          Trainer Name
                          <Typography sx={{ color: "red" }} component="span">
                            *
                          </Typography>
                        </Typography>
                      </InputLabel>
                      <CustomTextField
                        name="trainer"
                        value={formik.values.trainer}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.trainer &&
                          Boolean(formik.errors.trainer)
                        }
                      />
                    </Grid>
                    {formik.touched.trainer && formik.errors.trainer && (
                      <Typography
                        sx={{
                          color: "#d32f2f",
                          fontSize: "0.75rem",
                          mt: "-15px",
                        }}
                      >
                        {formik.errors.trainer}
                      </Typography>
                    )}
                    <Grid item xs={12}>
                      <InputLabel>
                        <Typography sx={{ color: "black", fontWeight: "500" }}>
                          Skills
                          <Typography sx={{ color: "red" }} component="span">
                            *
                          </Typography>
                        </Typography>
                      </InputLabel>
                      <CustomTextField
                        name="skills"
                        value={formik.values.skills}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.skills && Boolean(formik.errors.skills)
                        }
                      />
                    </Grid>
                    {formik.touched.skills && formik.errors.skills && (
                      <Typography
                        sx={{
                          color: "#d32f2f",
                          fontSize: "0.75rem",
                          mt: "-15px",
                        }}
                      >
                        {formik.errors.skills}
                      </Typography>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
          <br />
          <br />
          <Box
            style={{
              padding: "20px",
              textAlign: "left",
              boxShadow: "0.5px 0.5px 5px 0.5px rgb(223, 218, 218)",
              borderRadius: "20px",
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} md={5}>
                <Typography variant="h6" sx={{ fontWeight: "400" }}>
                  Course Category
                  <Typography sx={{ fontSize: "13px", color: "#a3a3b8" }}>
                    Please add your course category and subcategory
                  </Typography>
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Grid container>
                  <Grid item xs={12}>
                    <Typography sx={{ color: "black", fontWeight: "500" }}>
                      Course Category
                      <Typography sx={{ color: "red" }} component="span">
                        *
                      </Typography>
                    </Typography>
                    <FormControl fullWidth>
                      <Select
                        name="courseCategory"
                        value={formik.values.courseCategory}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.courseCategory &&
                          Boolean(formik.errors.courseCategory)
                        }
                        sx={{ width: "80%", height: 40 }}
                      >
                        {category.map((category) => (
                          <MenuItem
                            key={category.id}
                            value={category.categoryCode}
                          >
                            {category.categoryName}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <br />
                  {formik.touched.courseCategory &&
                    formik.errors.courseCategory && (
                      <Typography
                        sx={{
                          color: "#d32f2f",
                          fontSize: "0.75rem",
                        }}
                      >
                        {formik.errors.courseCategory}
                      </Typography>
                    )}
                </Grid>
                <br />
                <Grid container>
                  <Grid item xs={12}>
                    <Typography sx={{ color: "black", fontWeight: "500" }}>
                      Course SubCategory
                      <Typography sx={{ color: "red" }} component="span">
                        *
                      </Typography>
                    </Typography>
                    <FormControl fullWidth>
                      <Select
                        name="courseSubCategory"
                        value={formik.values.courseSubCategory}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        disabled={!formik.values.courseCategory}
                        error={
                          formik.touched.courseSubCategory &&
                          Boolean(formik.errors.courseSubCategory)
                        }
                        sx={{ width: "80%", height: 40 }}
                      >
                        {getSubCategory
                          .filter(
                            (item) =>
                              item.categoryName === formik.values.courseCategory
                          )
                          .map((item) => (
                            <MenuItem
                              key={item.id}
                              value={item.subcategoryCode}
                            >
                              {item.subcategoryName}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <br />
                  {formik.touched.courseSubCategory &&
                    formik.errors.courseSubCategory && (
                      <Typography
                        sx={{
                          color: "#d32f2f",
                          fontSize: "0.75rem",
                        }}
                      >
                        {formik.errors.courseSubCategory}
                      </Typography>
                    )}
                </Grid>
              </Grid>
            </Grid>
          </Box>
          <br />
          <br />
          <Box
            style={{
              padding: "20px",
              textAlign: "left",
              boxShadow: "0.5px 0.5px 5px 0.5px rgb(223, 218, 218)",
              borderRadius: "20px",
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} md={5}>
                <Typography variant="h6" sx={{ fontWeight: "400" }}>
                  Course Status
                  <Typography sx={{ fontSize: "13px", color: "#a3a3b8" }}>
                    Please provide your course status
                  </Typography>
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Grid container>
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <Stack direction="row">
                        <Typography>Active</Typography>
                        <Switch
                          name="isActive"
                          color="primary"
                          checked={formik.values.isActive}
                          onChange={formik.handleChange}
                        />
                      </Stack>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
          <Box pt={3} display="flex" flexDirection="row-reverse">
            <Stack direction="row" spacing={4}>
              <Link to={AppRouts.COURSE}>
                <Button type="cancle" variant="contained" color="primary">
                  Cancel
                </Button>
              </Link>
              <Button type="submit" variant="contained" color="primary">
                Submit
              </Button>
            </Stack>
          </Box>
        </form>
      </div>
    </div>
  );
};

export default AddCourse;
