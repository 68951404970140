import React, { useEffect, useState } from "react";
import SubHeader from "./SubHeader";
import { Link, useParams } from "react-router-dom";
import { AppRouts, StorageBucket, TableName } from "./Constant";
import useSupabase from "./utils/useSupabase";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Grid,
  InputLabel,
  Typography,
  TextField,
  Stack,
  Button,
} from "@mui/material";
import ImageUpload from "./ImageUpload";
import { useFormik } from "formik";
import * as yup from "yup";
import CustomTextField from "./CustomTextField";
import { removeImage, uploadImage } from "./Helperfunction";
import { showToast } from "./toastUtils";
const AddWorkshopDetails = () => {
  const supabase = useSupabase();
  const navigate = useNavigate();
  const params = useParams();
  const { id } = useParams();
  const { detailId } = useParams();
  const [imagesPreview, setImagesPreview] = useState([]);
  const [originalImage, setOriginalImage] = useState();
  const [isNewImageUp, setIsNewImageUp] = useState(false);
  const initialValues = {
    workshopName: "",
    workshopDesc: "",
    topic1: "",
    topic2: "",
    topic3: "",
    topic4: "",
    topic5: "",
    topic6: "",
    desc1: "",
    desc2: "",
    desc3: "",
    desc4: "",
    desc5: "",
    desc6: "",
    faq1: "",
    faq2: "",
    faq3: "",
    faq4: "",
    faq5: "",
    faq6: "",
    answer1: "",
    answer2: "",
    answer3: "",
    answer4: "",
    answer5: "",
    answer6: "",
  };
  const validationSchema = yup.object({
    workshopName: yup.string().required("Workshop Name is required"),
    workshopDesc: yup.string().required("Workshop Desc is required"),
    topic1: yup.string().required("Topic Name is required"),
    topic2: yup.string().required("Topic Name is required"),
    topic3: yup.string().required("Topic Name is required"),
    topic4: yup.string().required("Topic Name is required"),
    topic5: yup.string().required("Topic Name is required"),
    topic6: yup.string().required("Topic Name is required"),
    desc1: yup.string().required("Topic Desc is required"),
    desc2: yup.string().required("Topic Desc is required"),
    desc3: yup.string().required("Topic Desc is required"),
    desc4: yup.string().required("Topic Desc is required"),
    desc5: yup.string().required("Topic Desc is required"),
    desc6: yup.string().required("Topic Desc is required"),
    faq1: yup.string().required("FA Question is required"),
    faq2: yup.string().required("FA Question is required"),
    faq3: yup.string().required("FA Question is required"),
    faq4: yup.string().required("FA Question is required"),
    faq5: yup.string().required("FA Question is required"),
    faq6: yup.string().required("FA Question is required"),
    answer1: yup.string().required("Answer is required"),
    answer2: yup.string().required("Answer is required"),
    answer3: yup.string().required("Answer is required"),
    answer4: yup.string().required("Answer is required"),
    answer5: yup.string().required("Answer is required"),
    answer6: yup.string().required("Answer is required"),
  });
  useEffect(
    () => {
      const fetchWorkshopData = async () => {
        const workshopId = params.detailId;
        const { data, error } = await supabase
          .from(TableName.WORKSHOPDETAILS)
          .select()
          .eq("detailId", workshopId);

        if (error) {
          console.error("Error fetching Workshop data:", error.message);
        } else if (data && data.length > 0) {
          const workshop = data[0];
          formik.setValues({
            workshopName: workshop.workshopName,
            workshopDesc: workshop.workshopDesc,
            topic1: workshop.topic1,
            topic2: workshop.topic2,
            topic3: workshop.topic3,
            topic4: workshop.topic4,
            topic5: workshop.topic5,
            topic6: workshop.topic6,
            desc1: workshop.desc1,
            desc2: workshop.desc2,
            desc3: workshop.desc3,
            desc4: workshop.desc4,
            desc5: workshop.desc5,
            desc6: workshop.desc6,
            faq1: workshop.faq1,
            faq2: workshop.faq2,
            faq3: workshop.faq3,
            faq4: workshop.faq4,
            faq5: workshop.faq5,
            faq6: workshop.faq6,
            answer1: workshop.answer1,
            answer2: workshop.answer2,
            answer3: workshop.answer3,
            answer4: workshop.answer4,
            answer5: workshop.answer5,
            answer6: workshop.answer6,
          });
          if (workshop.imageUrl) {
            setImagesPreview([{ imageUrl: workshop.imageUrl }]);
          }
          setOriginalImage(workshop.imageUrl);
        }
      };

      if (params.detailId) {
        fetchWorkshopData();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [params.detailId, supabase]
  );
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: async (values, event) => {
      if (params.detailId) {
        let publicUrl = originalImage;
        if (isNewImageUp) {
          await removeImage(StorageBucket.WORKSHOPDETAILS, originalImage);
          publicUrl = await uploadImage(
            StorageBucket.WORKSHOPDETAILS,
            imagesPreview[0].imageFile
          );
        }
        const { data, error } = await supabase
          .from(TableName.WORKSHOPDETAILS)
          .update([
            {
                workshopName: values.workshopName,
                workshopDesc: values.workshopDesc,
              topic1: values.topic1,
              topic2: values.topic2,
              topic3: values.topic3,
              topic4: values.topic4,
              topic5: values.topic5,
              topic6: values.topic6,
              desc1: values.desc1,
              desc2: values.desc2,
              desc3: values.desc3,
              desc4: values.desc4,
              desc5: values.desc5,
              desc6: values.desc6,
              faq1: values.faq1,
              faq2: values.faq2,
              faq3: values.faq3,
              faq4: values.faq4,
              faq5: values.faq5,
              faq6: values.faq6,
              answer1: values.answer1,
              answer2: values.answer2,
              answer3: values.answer3,
              answer4: values.answer4,
              answer5: values.answer5,
              answer6: values.answer6,
              imageUrl: publicUrl,
            },
          ])
          .eq("detailId", detailId);
        if (!error) {
          console.log("workshop data update", data);
          navigate(`${AppRouts.WORKSHOPDETAILS}/${id}`);
          showToast("update", "workshop Details");
        }
      } else {
        try {
          let publicUrl = "";
          if (imagesPreview.length > 0) {
            publicUrl = await uploadImage(
              StorageBucket.WORKSHOPDETAILS,
              imagesPreview[0].imageFile
            );
          }
          const { error: insertError } = await supabase
            .from(TableName.WORKSHOPDETAILS)
            .insert([
              {
                workshopId: id,
                workshopName: values.workshopName,
                workshopDesc: values.workshopDesc,
                topic1: values.topic1,
                topic2: values.topic2,
                topic3: values.topic3,
                topic4: values.topic4,
                topic5: values.topic5,
                topic6: values.topic6,
                desc1: values.desc1,
                desc2: values.desc2,
                desc3: values.desc3,
                desc4: values.desc4,
                desc5: values.desc5,
                desc6: values.desc6,
                faq1: values.faq1,
                faq2: values.faq2,
                faq3: values.faq3,
                faq4: values.faq4,
                faq5: values.faq5,
                faq6: values.faq6,
                answer1: values.answer1,
                answer2: values.answer2,
                answer3: values.answer3,
                answer4: values.answer4,
                answer5: values.answer5,
                answer6: values.answer6,
                imageUrl: publicUrl,
              },
            ]);

          if (insertError) {
            console.error("Error inserting data:", insertError.message);
            return;
          }

          navigate(`${AppRouts.WORKSHOPDETAILS}/${id}`);
          showToast("success", "workshop Details");
        } catch (error) {
          console.error("Error:", error.message);
        }
      }
    },
  });
  const handleImageUpload = async (event) => {
    if (event?.target?.files?.length > 0) {
      const imageUrl = URL.createObjectURL(event?.target?.files[0]);
      setImagesPreview([
        {
          imageFile: event?.target?.files[0],
          imageUrl: imageUrl,
        },
      ]);
      setIsNewImageUp(true);
      setOriginalImage(imageUrl);
    }
  };

  const handleCancelImage = (index) => {
    setImagesPreview([]);
    setOriginalImage(null);
  };
  return (
    <div>
      <SubHeader
        title={params.id ? "Edit workshop Details " : "Add workshop Details"}
        subtitle="workshop Details"
        back="workshop Details"
        linkProps={{
          to: `${AppRouts.WORKSHOPDETAILS}/${id}`,
        }}
      />
      <div style={{ padding: "35px" }}>
        <form onSubmit={formik.handleSubmit}>
          <Box
            style={{
              padding: "20px",
              textAlign: "left",
              boxShadow: "0.5px 0.5px 5px 0.5px rgb(223, 218, 218)",
              borderRadius: "20px",
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} md={5}>
                <Typography variant="h6" sx={{ fontWeight: "400" }}>
                workshop Basic Details
                  <Typography sx={{ fontSize: "13px", color: "#a3a3b8" }}>
                    Please provide workshop details
                  </Typography>
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Grid container>
                  <Grid item xs={12}>
                    <div style={{ margin: 17 }}>
                      <ImageUpload
                        isMultiImages={false}
                        handleImageUpload={handleImageUpload}
                        handleCancelImage={handleCancelImage}
                        uploadedImages={imagesPreview}
                        title="Click to upload workshop Image"
                      />
                    </div>
                  </Grid>
                  <br />
                  <Grid item xs={12}>
                    <InputLabel>
                      <Typography sx={{ color: "black", fontWeight: "500" }}>
                      Workshop Name
                        <Typography sx={{ color: "red" }} component="span">
                          *
                        </Typography>
                      </Typography>
                    </InputLabel>
                    <CustomTextField
                      name="workshopName"
                      value={formik.values.workshopName}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.workshopName &&
                        Boolean(formik.errors.workshopName)
                      }
                    />
                    {formik.touched.workshopName && formik.errors.workshopName && (
                      <Typography
                        sx={{
                          color: "#d32f2f",
                          fontSize: "0.75rem",
                          mt: "-15px",
                        }}
                      >
                        {formik.errors.workshopName}
                      </Typography>
                    )}
                    <Grid item xs={12}>
                      <InputLabel>
                        <Typography sx={{ color: "black", fontWeight: "500" }}>
                        Workshop Description
                          <Typography sx={{ color: "red" }} component="span">
                            *
                          </Typography>
                        </Typography>
                      </InputLabel>
                      <TextField
                        name="workshopDesc"
                        value={formik.values.workshopDesc}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.workshopDesc &&
                          Boolean(formik.errors.workshopDesc)
                        }
                        sx={{ width: "80%" }}
                        id="outlined-multiline-static"
                        multiline
                        rows={4}
                      />
                    </Grid>

                    {formik.touched.workshopDesc && formik.errors.workshopDesc && (
                      <Typography
                        sx={{
                          color: "#d32f2f",
                          fontSize: "0.75rem",
                        }}
                      >
                        {formik.errors.workshopDesc}
                      </Typography>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
          <br />
          <br />
          <Box
            style={{
              padding: "20px",
              textAlign: "left",
              boxShadow: "0.5px 0.5px 5px 0.5px rgb(223, 218, 218)",
              borderRadius: "20px",
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} md={5}>
                <Typography variant="h6" sx={{ fontWeight: "400" }}>
                Workshop Topics
                  <Typography sx={{ fontSize: "13px", color: "#a3a3b8" }}>
                    Please provide Workshop topics
                  </Typography>
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Grid container>
                  <Grid item xs={12}>
                    <Grid item xs={12}>
                      <InputLabel>
                        <Typography sx={{ color: "black", fontWeight: "500" }}>
                          Topic 1
                          <Typography sx={{ color: "red" }} component="span">
                            *
                          </Typography>
                        </Typography>
                      </InputLabel>
                      <CustomTextField
                        name="topic1"
                        value={formik.values.topic1}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.topic1 && Boolean(formik.errors.topic1)
                        }
                      />
                    </Grid>
                    {formik.touched.topic1 && formik.errors.topic1 && (
                      <Typography
                        sx={{
                          color: "#d32f2f",
                          fontSize: "0.75rem",
                          mt: "-15px",
                        }}
                      >
                        {formik.errors.topic1}
                      </Typography>
                    )}

                    <Grid item xs={12}>
                      <InputLabel>
                        <Typography sx={{ color: "black", fontWeight: "500" }}>
                          Topic Desc 1
                          <Typography sx={{ color: "red" }} component="span">
                            *
                          </Typography>
                        </Typography>
                      </InputLabel>
                      <TextField
                        sx={{ width: "80%" }}
                        id="outlined-multiline-static"
                        multiline
                        rows={4}
                        name="desc1"
                        value={formik.values.desc1}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.desc1 && Boolean(formik.errors.desc1)
                        }
                      />
                      {formik.touched.desc1 && formik.errors.desc1 && (
                        <Typography
                          sx={{
                            color: "#d32f2f",
                            fontSize: "0.75rem",
                            //mt: "-15px",
                          }}
                        >
                          {formik.errors.desc1}
                        </Typography>
                      )}
                    </Grid>
                    <br />
                    <Grid item xs={12}>
                      <InputLabel>
                        <Typography sx={{ color: "black", fontWeight: "500" }}>
                          Topic 2
                          <Typography sx={{ color: "red" }} component="span">
                            *
                          </Typography>
                        </Typography>
                      </InputLabel>
                      <CustomTextField
                        name="topic2"
                        value={formik.values.topic2}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.topic2 && Boolean(formik.errors.topic2)
                        }
                      />
                    </Grid>
                    {formik.touched.topic2 && formik.errors.topic2 && (
                      <Typography
                        sx={{
                          color: "#d32f2f",
                          fontSize: "0.75rem",
                          mt: "-15px",
                        }}
                      >
                        {formik.errors.topic2}
                      </Typography>
                    )}
                    <Grid item xs={12}>
                      <InputLabel>
                        <Typography sx={{ color: "black", fontWeight: "500" }}>
                          Topic Desc 2
                          <Typography sx={{ color: "red" }} component="span">
                            *
                          </Typography>
                        </Typography>
                      </InputLabel>
                      <TextField
                        sx={{ width: "80%" }}
                        id="outlined-multiline-static"
                        multiline
                        rows={4}
                        name="desc2"
                        value={formik.values.desc2}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.desc2 && Boolean(formik.errors.desc2)
                        }
                      />
                      {formik.touched.desc2 && formik.errors.desc2 && (
                        <Typography
                          sx={{
                            color: "#d32f2f",
                            fontSize: "0.75rem",
                          }}
                        >
                          {formik.errors.desc2}
                        </Typography>
                      )}
                    </Grid>
                    <br />
                    <Grid item xs={12}>
                      <InputLabel>
                        <Typography sx={{ color: "black", fontWeight: "500" }}>
                          Topic 3
                          <Typography sx={{ color: "red" }} component="span">
                            *
                          </Typography>
                        </Typography>
                      </InputLabel>
                      <CustomTextField
                        name="topic3"
                        value={formik.values.topic3}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.topic3 && Boolean(formik.errors.topic3)
                        }
                      />
                    </Grid>
                    {formik.touched.topic3 && formik.errors.topic3 && (
                      <Typography
                        sx={{
                          color: "#d32f2f",
                          fontSize: "0.75rem",
                          mt: "-15px",
                        }}
                      >
                        {formik.errors.topic3}
                      </Typography>
                    )}
                    <Grid item xs={12}>
                      <InputLabel>
                        <Typography sx={{ color: "black", fontWeight: "500" }}>
                          Topic Desc 3
                          <Typography sx={{ color: "red" }} component="span">
                            *
                          </Typography>
                        </Typography>
                      </InputLabel>
                      <TextField
                        sx={{ width: "80%" }}
                        id="outlined-multiline-static"
                        multiline
                        rows={4}
                        name="desc3"
                        value={formik.values.desc3}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.desc3 && Boolean(formik.errors.desc3)
                        }
                      />
                      {formik.touched.desc3 && formik.errors.desc3 && (
                        <Typography
                          sx={{
                            color: "#d32f2f",
                            fontSize: "0.75rem",
                          }}
                        >
                          {formik.errors.desc3}
                        </Typography>
                      )}
                    </Grid>
                    <br />
                    <Grid item xs={12}>
                      <InputLabel>
                        <Typography sx={{ color: "black", fontWeight: "500" }}>
                          Topic 4
                          <Typography sx={{ color: "red" }} component="span">
                            *
                          </Typography>
                        </Typography>
                      </InputLabel>
                      <CustomTextField
                        name="topic4"
                        value={formik.values.topic4}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.topic4 && Boolean(formik.errors.topic4)
                        }
                      />
                    </Grid>
                    {formik.touched.topic4 && formik.errors.topic4 && (
                      <Typography
                        sx={{
                          color: "#d32f2f",
                          fontSize: "0.75rem",
                          mt: "-15px",
                        }}
                      >
                        {formik.errors.topic4}
                      </Typography>
                    )}
                    <Grid item xs={12}>
                      <InputLabel>
                        <Typography sx={{ color: "black", fontWeight: "500" }}>
                          Topic Desc 4
                          <Typography sx={{ color: "red" }} component="span">
                            *
                          </Typography>
                        </Typography>
                      </InputLabel>
                      <TextField
                        sx={{ width: "80%" }}
                        id="outlined-multiline-static"
                        multiline
                        rows={4}
                        name="desc4"
                        value={formik.values.desc4}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.desc4 && Boolean(formik.errors.desc4)
                        }
                      />
                      {formik.touched.desc4 && formik.errors.desc4 && (
                        <Typography
                          sx={{
                            color: "#d32f2f",
                            fontSize: "0.75rem",
                          }}
                        >
                          {formik.errors.desc4}
                        </Typography>
                      )}
                    </Grid>
                    <br />
                    <Grid item xs={12}>
                      <InputLabel>
                        <Typography sx={{ color: "black", fontWeight: "500" }}>
                          Topic 5
                          <Typography sx={{ color: "red" }} component="span">
                            *
                          </Typography>
                        </Typography>
                      </InputLabel>
                      <CustomTextField
                        name="topic5"
                        value={formik.values.topic5}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.topic5 && Boolean(formik.errors.topic5)
                        }
                      />
                    </Grid>
                    {formik.touched.topic5 && formik.errors.topic5 && (
                      <Typography
                        sx={{
                          color: "#d32f2f",
                          fontSize: "0.75rem",
                          mt: "-15px",
                        }}
                      >
                        {formik.errors.topic5}
                      </Typography>
                    )}
                    <Grid item xs={12}>
                      <InputLabel>
                        <Typography sx={{ color: "black", fontWeight: "500" }}>
                          Topic Desc 5
                          <Typography sx={{ color: "red" }} component="span">
                            *
                          </Typography>
                        </Typography>
                      </InputLabel>
                      <TextField
                        sx={{ width: "80%" }}
                        id="outlined-multiline-static"
                        multiline
                        rows={4}
                        name="desc5"
                        value={formik.values.desc5}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.desc5 && Boolean(formik.errors.desc5)
                        }
                      />
                      {formik.touched.desc5 && formik.errors.desc5 && (
                        <Typography
                          sx={{
                            color: "#d32f2f",
                            fontSize: "0.75rem",
                          }}
                        >
                          {formik.errors.desc5}
                        </Typography>
                      )}
                    </Grid>
                    <br />
                    <Grid item xs={12}>
                      <InputLabel>
                        <Typography sx={{ color: "black", fontWeight: "500" }}>
                          Topic 6
                          <Typography sx={{ color: "red" }} component="span">
                            *
                          </Typography>
                        </Typography>
                      </InputLabel>
                      <CustomTextField
                        name="topic6"
                        value={formik.values.topic6}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.topic6 && Boolean(formik.errors.topic6)
                        }
                      />
                    </Grid>
                    {formik.touched.topic6 && formik.errors.topic6 && (
                      <Typography
                        sx={{
                          color: "#d32f2f",
                          fontSize: "0.75rem",
                          mt: "-15px",
                        }}
                      >
                        {formik.errors.topic6}
                      </Typography>
                    )}
                    <Grid item xs={12}>
                      <InputLabel>
                        <Typography sx={{ color: "black", fontWeight: "500" }}>
                          Topic Desc 6
                          <Typography sx={{ color: "red" }} component="span">
                            *
                          </Typography>
                        </Typography>
                      </InputLabel>
                      <TextField
                        sx={{ width: "80%" }}
                        id="outlined-multiline-static"
                        multiline
                        rows={4}
                        name="desc6"
                        value={formik.values.desc6}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.desc6 && Boolean(formik.errors.desc6)
                        }
                      />
                      {formik.touched.desc6 && formik.errors.desc6 && (
                        <Typography
                          sx={{
                            color: "#d32f2f",
                            fontSize: "0.75rem",
                          }}
                        >
                          {formik.errors.desc6}
                        </Typography>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
          <br />
          <br />
          <Box
            style={{
              padding: "20px",
              textAlign: "left",
              boxShadow: "0.5px 0.5px 5px 0.5px rgb(223, 218, 218)",
              borderRadius: "20px",
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} md={5}>
                <Typography variant="h6" sx={{ fontWeight: "400" }}>
                  FAQ Section
                  <Typography sx={{ fontSize: "13px", color: "#a3a3b8" }}>
                    Please provide FAQ
                  </Typography>
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Grid container>
                  <Grid item xs={12}>
                    <Grid item xs={12}>
                      <InputLabel>
                        <Typography sx={{ color: "black", fontWeight: "500" }}>
                          FAQ 1
                          <Typography sx={{ color: "red" }} component="span">
                            *
                          </Typography>
                        </Typography>
                      </InputLabel>
                      <CustomTextField
                        name="faq1"
                        value={formik.values.faq1}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.faq1 && Boolean(formik.errors.faq1)
                        }
                      />
                    </Grid>
                    {formik.touched.faq1 && formik.errors.faq1 && (
                      <Typography
                        sx={{
                          color: "#d32f2f",
                          fontSize: "0.75rem",
                          mt: "-15px",
                        }}
                      >
                        {formik.errors.faq1}
                      </Typography>
                    )}

                    <Grid item xs={12}>
                      <InputLabel>
                        <Typography sx={{ color: "black", fontWeight: "500" }}>
                          Answer 1
                          <Typography sx={{ color: "red" }} component="span">
                            *
                          </Typography>
                        </Typography>
                      </InputLabel>
                      <TextField
                        sx={{ width: "80%" }}
                        id="outlined-multiline-static"
                        multiline
                        rows={4}
                        name="answer1"
                        value={formik.values.answer1}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.answer1 &&
                          Boolean(formik.errors.answer1)
                        }
                      />
                      {formik.touched.answer1 && formik.errors.answer1 && (
                        <Typography
                          sx={{
                            color: "#d32f2f",
                            fontSize: "0.75rem",
                          }}
                        >
                          {formik.errors.answer1}
                        </Typography>
                      )}
                    </Grid>
                    <br />
                    <Grid item xs={12}>
                      <InputLabel>
                        <Typography sx={{ color: "black", fontWeight: "500" }}>
                          FAQ 2
                          <Typography sx={{ color: "red" }} component="span">
                            *
                          </Typography>
                        </Typography>
                      </InputLabel>
                      <CustomTextField
                        name="faq2"
                        value={formik.values.faq2}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.faq2 && Boolean(formik.errors.faq2)
                        }
                      />
                    </Grid>
                    {formik.touched.faq2 && formik.errors.faq2 && (
                      <Typography
                        sx={{
                          color: "#d32f2f",
                          fontSize: "0.75rem",
                          mt: "-15px",
                        }}
                      >
                        {formik.errors.faq2}
                      </Typography>
                    )}
                    <Grid item xs={12}>
                      <InputLabel>
                        <Typography sx={{ color: "black", fontWeight: "500" }}>
                          Answer 2
                          <Typography sx={{ color: "red" }} component="span">
                            *
                          </Typography>
                        </Typography>
                      </InputLabel>
                      <TextField
                        sx={{ width: "80%" }}
                        id="outlined-multiline-static"
                        multiline
                        rows={4}
                        name="answer2"
                        value={formik.values.answer2}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.answer2 &&
                          Boolean(formik.errors.answer2)
                        }
                      />
                      {formik.touched.answer2 && formik.errors.answer2 && (
                        <Typography
                          sx={{
                            color: "#d32f2f",
                            fontSize: "0.75rem",
                          }}
                        >
                          {formik.errors.answer2}
                        </Typography>
                      )}
                    </Grid>
                    <br />
                    <Grid item xs={12}>
                      <InputLabel>
                        <Typography sx={{ color: "black", fontWeight: "500" }}>
                          FAQ 3
                          <Typography sx={{ color: "red" }} component="span">
                            *
                          </Typography>
                        </Typography>
                      </InputLabel>
                      <CustomTextField
                        name="faq3"
                        value={formik.values.faq3}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.faq3 && Boolean(formik.errors.faq3)
                        }
                      />
                    </Grid>
                    {formik.touched.faq3 && formik.errors.faq3 && (
                      <Typography
                        sx={{
                          color: "#d32f2f",
                          fontSize: "0.75rem",
                          mt: "-15px",
                        }}
                      >
                        {formik.errors.faq3}
                      </Typography>
                    )}
                    <Grid item xs={12}>
                      <InputLabel>
                        <Typography sx={{ color: "black", fontWeight: "500" }}>
                          Answer 3
                          <Typography sx={{ color: "red" }} component="span">
                            *
                          </Typography>
                        </Typography>
                      </InputLabel>
                      <TextField
                        sx={{ width: "80%" }}
                        id="outlined-multiline-static"
                        multiline
                        rows={4}
                        name="answer3"
                        value={formik.values.answer3}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.answer3 &&
                          Boolean(formik.errors.answer3)
                        }
                      />
                      {formik.touched.answer3 && formik.errors.answer3 && (
                        <Typography
                          sx={{
                            color: "#d32f2f",
                            fontSize: "0.75rem",
                          }}
                        >
                          {formik.errors.answer3}
                        </Typography>
                      )}
                    </Grid>
                    <br />
                    <Grid item xs={12}>
                      <InputLabel>
                        <Typography sx={{ color: "black", fontWeight: "500" }}>
                          FAQ 4
                          <Typography sx={{ color: "red" }} component="span">
                            *
                          </Typography>
                        </Typography>
                      </InputLabel>
                      <CustomTextField
                        name="faq4"
                        value={formik.values.faq4}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.faq4 && Boolean(formik.errors.faq4)
                        }
                      />
                    </Grid>
                    {formik.touched.faq4 && formik.errors.faq4 && (
                      <Typography
                        sx={{
                          color: "#d32f2f",
                          fontSize: "0.75rem",
                          mt: "-15px",
                        }}
                      >
                        {formik.errors.faq4}
                      </Typography>
                    )}
                    <Grid item xs={12}>
                      <InputLabel>
                        <Typography sx={{ color: "black", fontWeight: "500" }}>
                          Answer 4
                          <Typography sx={{ color: "red" }} component="span">
                            *
                          </Typography>
                        </Typography>
                      </InputLabel>
                      <TextField
                        sx={{ width: "80%" }}
                        id="outlined-multiline-static"
                        multiline
                        rows={4}
                        name="answer4"
                        value={formik.values.answer4}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.answer4 &&
                          Boolean(formik.errors.answer4)
                        }
                      />
                      {formik.touched.answer4 && formik.errors.answer4 && (
                        <Typography
                          sx={{
                            color: "#d32f2f",
                            fontSize: "0.75rem",
                          }}
                        >
                          {formik.errors.answer4}
                        </Typography>
                      )}
                    </Grid>
                    <br />
                    <Grid item xs={12}>
                      <InputLabel>
                        <Typography sx={{ color: "black", fontWeight: "500" }}>
                          FAQ 5
                          <Typography sx={{ color: "red" }} component="span">
                            *
                          </Typography>
                        </Typography>
                      </InputLabel>
                      <CustomTextField
                        name="faq5"
                        value={formik.values.faq5}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.faq5 && Boolean(formik.errors.faq5)
                        }
                      />
                    </Grid>
                    {formik.touched.faq5 && formik.errors.faq5 && (
                      <Typography
                        sx={{
                          color: "#d32f2f",
                          fontSize: "0.75rem",
                          mt: "-15px",
                        }}
                      >
                        {formik.errors.faq5}
                      </Typography>
                    )}
                    <Grid item xs={12}>
                      <InputLabel>
                        <Typography sx={{ color: "black", fontWeight: "500" }}>
                          Answer 5
                          <Typography sx={{ color: "red" }} component="span">
                            *
                          </Typography>
                        </Typography>
                      </InputLabel>
                      <TextField
                        sx={{ width: "80%" }}
                        id="outlined-multiline-static"
                        multiline
                        rows={4}
                        name="answer5"
                        value={formik.values.answer5}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.answer5 &&
                          Boolean(formik.errors.answer5)
                        }
                      />
                      {formik.touched.answer5 && formik.errors.answer5 && (
                        <Typography
                          sx={{
                            color: "#d32f2f",
                            fontSize: "0.75rem",
                          }}
                        >
                          {formik.errors.answer5}
                        </Typography>
                      )}
                    </Grid>
                    <br />
                    <Grid item xs={12}>
                      <InputLabel>
                        <Typography sx={{ color: "black", fontWeight: "500" }}>
                          FAQ 6
                          <Typography sx={{ color: "red" }} component="span">
                            *
                          </Typography>
                        </Typography>
                      </InputLabel>
                      <CustomTextField
                        name="faq6"
                        value={formik.values.faq6}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.faq6 && Boolean(formik.errors.faq6)
                        }
                      />
                    </Grid>
                    {formik.touched.faq6 && formik.errors.faq6 && (
                      <Typography
                        sx={{
                          color: "#d32f2f",
                          fontSize: "0.75rem",
                          mt: "-15px",
                        }}
                      >
                        {formik.errors.faq6}
                      </Typography>
                    )}
                    <Grid item xs={12}>
                      <InputLabel>
                        <Typography sx={{ color: "black", fontWeight: "500" }}>
                          Answer 6
                          <Typography sx={{ color: "red" }} component="span">
                            *
                          </Typography>
                        </Typography>
                      </InputLabel>
                      <TextField
                        sx={{ width: "80%" }}
                        id="outlined-multiline-static"
                        multiline
                        rows={4}
                        name="answer6"
                        value={formik.values.answer6}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.answer6 &&
                          Boolean(formik.errors.answer6)
                        }
                      />
                      {formik.touched.answer6 && formik.errors.answer6 && (
                        <Typography
                          sx={{
                            color: "#d32f2f",
                            fontSize: "0.75rem",
                          }}
                        >
                          {formik.errors.answer6}
                        </Typography>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
          <Box pt={3} display="flex" flexDirection="row-reverse">
            <Stack direction="row" spacing={4}>
              <Link to={`${AppRouts.WORKSHOPDETAILS}/${id}`}>
                <Button type="cancle" variant="contained" color="primary">
                  Cancel
                </Button>
              </Link>
              <Button type="submit" variant="contained" color="primary">
                Submit
              </Button>
            </Stack>
          </Box>
        </form>
      </div>
    </div>
  );
};

export default AddWorkshopDetails;
